import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ToggleButton from "../../toggleButton"
import "./styles/feedback.css"
import * as FaIcons from "react-icons/fa"
import { CreateQuestion, EditQuestion } from "../feedbackForm/createQuestion";
import Popup from "reactjs-popup";
import { db } from "../../../firebase";
import { globalEmployerData } from "../../../pages/createJob";
import { collection, doc, updateDoc } from "firebase/firestore";
import { sendNotification } from "../../notification";
import { X } from "lucide-react";


export const Feedback = ({
    enabled, 
    setEnabled,
    feedbackRemindersIn,
    setFeedbackRemindersIn,
    selectedQuestions,
    setSelectedQuestions,
    feedbackForms,
    setFeedbackForms,
    selectedForm,
    setSelectedForm,
    complete,
    setComplete
}) => {

    const [createQuestionPopupOpen, setCreateQuestionPopupOpen] = useState(false);
    const [createNewFormPopup, setCreateNewFormPopup] = useState(false);
    const [newFormName, setNewFormName] = useState('');
    const [questions, setQuestions] = useState([]);


    // const cancelCreation = () => {
    //     setNewFormName('');
    //     setQuestions([]);
    //     setCreateNewFormPopup(false);
    // }

    // const createNewForm = () => {
    //     if (newFormName === "") {
    //         sendNotification("Please enter a name for the form!");
    //         return;
    //     }
    //     if (questions.length === 0) {
    //         sendNotification("Please add at least one question to the form!");
    //         return;
    //     }
    //     const newForm = {
    //         name: newFormName,
    //         questions: questions
    //     }
    //     const updatedForms = [...feedbackForms, newForm];
    //     setFeedbackForms(updatedForms);

    //     // add it to the employer data in the database as well

    //     const employerRef = collection(db, 'teams');
    //     const employerDoc = doc(employerRef, globalEmployerData.id);
    //     updateDoc(employerDoc, {
    //         feedbackForms: updatedForms
    //     })

    //     setSelectedForm(updatedForms.length - 1);
    //     setNewFormName('');
    //     setQuestions([]);
    //     setCreateNewFormPopup(false);
    // }



    useEffect(() => {
        if (enabled &&  selectedQuestions.length === 0) {
            setComplete(false);
        } else {
            setComplete(true);
        }
    }, [enabled, selectedQuestions])


    const [loadFeedbackFormPopup, setLoadFeedbackFormPopup] = useState(false);
    const [saveFeedbackFormPopup, setSaveFeedbackFormPopup] = useState(false);

    const loadSelectedForm = () => {
        if (selectedForm !== null) {
            if (feedbackForms[selectedForm]?.questions.length > 0) {
                setSelectedQuestions(feedbackForms[selectedForm]?.questions);
            } else {
                setSelectedQuestions([]);
            }
        }

        setLoadFeedbackFormPopup(false);  
    }

    const saveFeedbackForm = async () => {
        const teamRef = doc(db, "teams", globalEmployerData.id);

        if ((selectedForm !== null) && (selectedForm !== "")) {
            // Update existing form
            const updatedForms = feedbackForms.map((form, index) => 
              index === parseInt(selectedForm) ? {
                name: form.name,
                questions: selectedQuestions
              } : form
            );
    
            await updateDoc(teamRef, { feedbackForms: updatedForms });
            setFeedbackForms(updatedForms);
        } else {
            // Create new form
            if (newFormName === "") {
                sendNotification("Please enter a name for the form!");
                return;
            }
            if (questions.length === 0) {
                sendNotification("Please add at least one question to the form!");
                return;
            }

            const newForm = {
              name: newFormName,
              questions: selectedQuestions,
            };
            const newForms = [...feedbackForms, newForm];
            await updateDoc(teamRef, { feedbackForms: newForms });
            setFeedbackForms(newForms);
          }

        setSaveFeedbackFormPopup(false);
        setSelectedForm(null);
    }

    const [createNewFormShow, setCreateNewFormShow] = useState(false);

    const cancelSaveFeedbackForm = () => {
        setSaveFeedbackFormPopup(false);
        setNewFormName('');
        setCreateNewFormShow(false);
    }

    useEffect(() => {
        if ((selectedForm !== null) && (selectedForm !== "")) {
            setNewFormName(null);
            setCreateNewFormShow(false);
        }
    }, [selectedForm])
    
    // handling the drag n drop functionality

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(selectedQuestions);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setSelectedQuestions(items);
    };

    return (
        <>
        <div className="interview_section_holder">
            <div className="feedback_category interview_feedback_category">
                <p className="interview_section_text">
                    Enable Feedback Forms
                </p>

                <ToggleButton isOn={enabled} setIsOn={setEnabled} />
            </div>  

            {
                enabled && (

                    <>

                    <div className="feedback_form interview_feedback_category">
                        <p style={{margin:"0", marginBottom:"0.5rem"}}><span className="interview_section_text">Feedback Form: </span></p>
                        
                        <div className="feedback_form_instructions">
                        
                            <div className="feedback_form_actions">
                                <Popup
                                    trigger={
                                        <button className="feedback_form_action_button">Load</button>
                                    }
                                    modal
                                    nested
                                    open={loadFeedbackFormPopup}
                                    onOpen={() => setLoadFeedbackFormPopup(true)}
                                    position="center center"
                                >
                                    <div className="popup_holder">
                                        <div className="popup" style={{width:"35%", height:"35%"}}>
                                            <h2 className="popup_heading">Load Feedback Form</h2>

                                            <div className="popup_body">
                                                <p>Select a feedback form to load:</p>
                                                
                                                <select className="feedback_form_select" style={{width:"75%", marginBottom:"1rem"}} value={selectedForm} onChange={(e) => setSelectedForm(e.target.value)}>
                                                    <option value={null}>Choose Feedback Form</option>
                                                    {
                                                        feedbackForms.map((form, index) => (
                                                            <option key={index} value={index} >{form.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            <div className="popup_bottom_actions" style={{padding: "0rem 2rem", boxSizing: "border-box"}}>
                                                <div className="close_popup" onClick={() => setLoadFeedbackFormPopup(false)}>Cancel</div>
                                                <div className="create_member" onClick={loadSelectedForm}>Load</div>
                                            </div>
                                        </div>
                                    </div>
                                </Popup>

                                

                                <Popup
                                    trigger={
                                        <button className="feedback_form_action_button">Save</button>
                                    }
                                    modal
                                    nested
                                    open={saveFeedbackFormPopup}
                                    onOpen={() => setSaveFeedbackFormPopup(true)}
                                    position="center center"
                                >
                                    <div className="popup_holder">
                                        <div className="popup" style={{width:"40%", height:"40%"}}>
                                            <h2 className="popup_heading">Save Interview Node</h2>

                                            <div className="popup_close" onClick={cancelSaveFeedbackForm}>
                                                <X />
                                            </div>

                                            <div className="popup_body">
                                                <div className="create_interview_override_save save_interview_section">
                                                    <p>Override existing saved item?</p>

                                                    <select className="feedback_form_select" style={{width:"75%", marginBottom:"1rem"}} value={selectedForm} onChange={(e) => setSelectedForm(e.target.value)}>
                                                        <option value="" selected>Choose Feedback Form</option>
                                                        {
                                                            feedbackForms.map((form, index) => (
                                                                <option key={index} value={index} >{form.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>

                                                <hr style={{background:"var(--primary)", border:".1px solid var(--secondary40)"}}></hr>

                                                <div className="create_interview_create_node save_interview_section">
                                                    <p>or <span className="create_interview_create_node_span" onClick={() => 
                                                    {
                                                        setCreateNewFormShow(!createNewFormShow);
                                                        setSelectedForm("");
                                                    }
                                                    }>create a new saved interview node</span></p>

                                                    {
                                                    createNewFormShow && (
                                                        <div className="save_interview_subsection">
                                                        <p style={{fontWeight:"500"}}>Name: </p>
                                                        <input type="text" className="save_interview_node_input" 
                                                            onChange={(e) => setNewFormName(e.target.value)}
                                                            value={newFormName}
                                                            placeholder="Enter the interview name here!"
                                                        />
                                                        </div>
                                                    )
                                                    }

                                                    
                                                </div>
                                            </div>

                                            <div className="popup_bottom_actions" style={{padding: "0rem 2rem", boxSizing:"border-box"}}>
                                            <div></div>
                                                <button className="save_interview_node_button" onClick={saveFeedbackForm}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Popup>
                            </div>

                            


                            <Popup
                                trigger={
                                    <button className="feedback_form_add_question">
                                        <FaIcons.FaPlus style={{marginRight:"0.5rem"}} />
                                        Add Question
                                    </button>
                                }
                                open={createQuestionPopupOpen}
                                onOpen={() => setCreateQuestionPopupOpen(true)}
                                modal
                                nested
                                position="center center"
                            >
                                <CreateQuestion
                                    questions={selectedQuestions}
                                    setQuestions={setSelectedQuestions}
                                    setPopupOpen={setCreateQuestionPopupOpen}
                                />
                            </Popup>

                         
                        </div>
        
                        <div className="feedback_area">
                            {
                                selectedQuestions?.length > 0 ? (
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="questions">
                                            {(provided) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {selectedQuestions.map((question, index) => (
                                                        <Draggable key={question.id || index} draggableId={question.id || `question-${index}`} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    className="feedback_form_question"
                                                                >
                                                                    <div className="question_details">
                                                                        <div {...provided.dragHandleProps} style={{ cursor: 'grab', marginRight: '10px' }}>
                                                                            <FaIcons.FaGripVertical />
                                                                        </div>
                                                                        <p className="question_text_display">{question.questionText} {question.questionRequired ? '*' : ''}</p>
                                                                        <p className="question_type_display">{question.questionType}</p>
                                                                    </div>
                                    
                                                                    <div className="question_actions">
                                                                        <EditQuestion 
                                                                            questions={selectedQuestions}
                                                                            setQuestions={setSelectedQuestions}
                                                                            index={index}
                                                                        />
                                    
                                                                        <FaIcons.FaTrash className="question_action_icon" onClick={() => {
                                                                            const updatedQuestions = [...selectedQuestions];
                                                                            updatedQuestions.splice(index, 1);
                                                                            setSelectedQuestions(updatedQuestions);
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                ) : (
                                    <p style={{fontSize:"1.1rem", opacity:".5"}}>No questions added yet!</p>
                                )
                            }
                        </div>
                    </div>
        
        
                    <div className="feedback_category interview_feedback_category">
                        <p className="interview_section_text">
                            Give reminders for feedback in
                        </p>
        
                        <select className="interview_section_select" value={feedbackRemindersIn} onChange={(e) => setFeedbackRemindersIn(parseInt(e.target.value))}>
                            <option value="0">Don't Remind</option>
                            <option value="12">12 hours</option>
                            <option value="24">24 hours</option>
                            <option value="36">36 hours</option>
                            <option value="48">2 days</option>
                        </select>
                    </div>
                    </>

                )
            }

            
        </div>


        </>
    )
}