import { Link } from "react-router-dom";
import "./styles/all_jobs.css"
import * as FaIcons from "react-icons/fa"
import { useEffect, useState } from "react";
import { initialCandidates } from "./job_subPages/pipeline";
import { auth, db } from "../firebase";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { PrettyCheckbox } from "../components/prettyCheckbox";
import { FiRefreshCcw } from "react-icons/fi";

export const AllJobs = () => {

    const [canRun, setCanRun] = useState(false);


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
            setCanRun(true);
            } else {
            // No user is signed in.
            setCanRun(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);


    useEffect( () => {



        const fetchJobs = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                throw new Error('No authenticated user found');
                }

                // Step 1: Get the user's member document to find their team_id
                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                throw new Error('Member not found');
                }

                const memberData = memberSnapshot.docs[0].data();
                const { team_id } = memberData;
                try {
                    const response = await fetch('https://api.skillpool.tech/getJobs', {
                        method: 'POST',
                        headers: {
                        'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ employer_id: team_id }),
                    });
                    const data = await response.json();
                    setJobs(data);
                    setFinishedLoading(true);
                } catch (error) {
                console.error("Error fetching jobs:", error);
                }


            } catch (error) {
                console.error("Failed to fetch jobs:", error);
            }
        };

        if (canRun) {
            fetchJobs();
        }


    }, [canRun]);

    const [jobs, setJobs] = useState([])


    const [searchTerm, setSearchTerm] = useState('');


    const filledJobs = jobs.filter(job => job.filled === true);

    const [finishedLoading, setFinishedLoading] = useState(false);

    // handling sorting

    const [sortBy, setSortBy] = useState('latest');

    const handleSortChange = (e) => {
        setSortBy(e.target.value);
    };

    const departments = ["Software Development", "Data Science", "Product Management", "Marketing", "Finance", "Sales", "Engineering", "Design", "Business", "Consulting", "Other"];

    const [departmentFilter, setDepartmentFilter] = useState([]);

    const toggleDepartmentFilter = (department) => {
        setDepartmentFilter(prevFilter => {
            if (prevFilter.includes(department)) {
                return prevFilter.filter(dep => dep !== department);
            } else {
                return [...prevFilter, department];
            }
        });
    };

    const [hideFilled, setHideFilled] = useState(true);

    // Filter jobs 

    const filteredJobs = jobs
    .filter(job =>
        job.jobTitle.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(job => job.deleted !== true
    )
    .filter(job => hideFilled ? job.filled !== true : true)
    .filter(job => departmentFilter.length === 0 || departmentFilter.includes('All') || departmentFilter.includes(job.department))
    .sort((a, b) => {
        if (sortBy === 'latest') {
            return new Date(b.createdTime) - new Date(a.createdTime);
        } else if (sortBy === 'oldest') {
            return new Date(a.createdTime) - new Date(b.createdTime);
        } else if (sortBy === 'name') {
            return a.jobTitle.localeCompare(b.jobTitle);
        } else if (sortBy === "department") {
            const aDepartmentIndex = departments.indexOf(a.department);
            const bDepartmentIndex = departments.indexOf(b.department);
            if (aDepartmentIndex === -1) {
                return 1;
            } else if (bDepartmentIndex === -1) {
                return -1;
            } else if (aDepartmentIndex === bDepartmentIndex) {
                // If departments are the same, sort by latest
                return new Date(b.createdTime) - new Date(a.createdTime);
            }
            return aDepartmentIndex - bDepartmentIndex;
        }
    });



    return (

        <>
            <div className = "jobs_page_content">

            <div className="jobs_page_heading">
                <h1>
                    Your Jobs
                </h1>
            </div>

            <div className="all_job_postings">

                <div className="jobs_page_header">
                    <div className="jobs_page_header_left">

                    </div>

                    <div className="jobs_page_header_info">
                        {filteredJobs?.length} Jobs
                    </div>

                    <div className="jobs_page_header_search">
                        <FaIcons.FaSearch />
                        <input
                            className="searchBar"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            placeholder={(
                                "Search!"
                            )}/>
                    </div>

                    <div className="create_new_job">
                        <Link to="new">
                            <FaIcons.FaPlus style={{marginRight:"1rem"}}/>
                            <p> Add New </p>
                        </Link>
                    </div>
                    
                </div>

                <div className="jobs_page_body">
                    <div className="jobs_page_sort_filters">
                        <div className='team_members_sort team_members_filter_item'>
                            <p className='team_members_filter_label' style={{marginBottom: "0.5rem"}}>Sort</p>

                            <select className='team_members_filter_select' value={sortBy} onChange={handleSortChange}>
                                <option value="latest">By Latest</option>
                                <option value="oldest">By Earliest</option>
                                <option value="name">By Name</option>
                                <option value="department">By Department</option>
                            </select>
                        </div>

                        <div className='team_members_and'>
                            <div className='line'></div>
                            <p className='and'>AND</p>
                            <div className='line'></div>
                        </div>

                        <div className='team_members_filter team_members_filter_item'>

                            <div className='team_members_filter_header'>
                                <p className='team_members_filter_label'>Filter</p>
                                <FiRefreshCcw className='team_members_filter_icon' onClick={() => {
                                    setHideFilled(true);
                                    setDepartmentFilter([]);
                                }} />
                            </div>

                            <div className="jobs_hide_filled">
                                <PrettyCheckbox checked={hideFilled} setChecked={setHideFilled} />

                                <p> Hide Filled Jobs</p>
                            </div>

                            <div className='jobs_filter_by_department'>
                                <p style={{fontWeight: "550"}}>By Department:</p>
{/* 
                                <div className='filter_departments_search'>
                                <FaIcons.FaSearch className='filter_departments_search_icon' style={{opacity:0.5}} />
                                <input type="text" className='filter_departments_search_input' placeholder='Search Departments' value={departmentsSearchTerm} onChange={(e) => setDepartmentsSearchTerm(e.target.value)} />
                                </div> */}

                                <div className="jobs_filter_departments_container">
                                {departments.map((department, idx) => (
                                    <div
                                    key={idx}
                                    onClick={() => toggleDepartmentFilter(department)}
                                    className={`filter_department ${departmentFilter.includes(department) ? "active" : ""}`}
                                    >
                                    {department}
                                    </div>
                                ))}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="jobs_holder">
                        {
                            filteredJobs.map((job) => (
                                <div className="job">
                                    <Link className = "job_link" to={`/job/${job.id}`}>
                                        <div className="job_posting">
                                            <div class = "job_posting_info">

                                                <div className="job_posting_details">
                                                    {/* Add the details regarding the filled status, or the needs attention */}
                                                    <div className={`job_status ${job.filled === true ? "filled" : "needs_attention"}`}>

                                                        {
                                                            job.filled === true ? (
                                                                <>
                                                                    <FaIcons.FaCheck />
                                                                    <p> Filled </p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <FaIcons.FaRegLightbulb />
                                                                    <p> In Review </p>
                                                                </>
                                                            )
                                                        }
                                                        {/* {
                                                            job.needsAttention === true && (
                                                                <>
                                                                <FaIcons.FaRegLightbulb />
                                                                <p> In Review </p>
                                                                </>
                                                            )
                                                        } */}
                                                        
                                                    </div>
                                                    <h2> {job.jobTitle} </h2>
                                                </div>
                                                <div className="job_department">
                                                    <p> {job.department || "None"} </p>
                                                </div>
                                            </div>


                                            <div className="end_of_posting">
                                                <FaIcons.FaArrowRight />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        }

                        {
                            filteredJobs.length === 0 && (
                                <div className="no_jobs_yet">
                                    <p> No jobs yet!</p>
                                </div>
                            )
                        }
                    </div>
                </div>

                {/* <div style={{justifyContent: "space-between", display: "flex", alignItems:"center", marginBottom:"2rem"}}>

                    <div style={{display:"flex", alignItems: "center"}}>
                        <FaIcons.FaSearch style={{opacity:0.5}} />
                        <input
                            className="searchBar"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            placeholder={(
                                "Search!"
                            )}/>

                    </div>


                    <div className="create_new_job"><Link to="new">

                        <FaIcons.FaPlus style={{marginRight:"1rem"}}/>

                        <p> Add New </p>

                    </Link></div>
                </div> */}


                {/* {
                    finishedLoading && (
                        <>
                            { !((filteredJobs.length === 0) && (filledJobs.length === 0)) && (
                                <div className="all_jobs_area">

                                    <div className="unfilled_jobs_area">
                                        {filteredJobs.map((job) => (
                                            <div className="job_posting_holder">
                                                <Link className = "job_posting_link" to={`/job/${job.id}`}>
                                                    <div className="job_posting">
                                                        <div class = "job_posting_info">
                                                            <h2> {job.jobTitle} </h2>
                                                            <span style={{fontWeight: "bold"}}> Location: </span>
                                                            <span>  {job?.remote ? 'Remote' : job?.location?.label}  </span>
                                                        </div>


                                                        <div className="end_of_posting">
                                                            <FaIcons.FaArrowRight />
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>

                                        ))}

                                        {
                                            filteredJobs.length === 0 && (
                                              <div className="no_jobs_yet">
                                                  <p> No active jobs yet!</p>
                                              </div>
                                            )
                                        }
                                    </div>

                                    {
                                      filledJobs.length !== 0 && (
                                        <div className="filled_jobs_area">

                                            <h1 style={{fontSize:"1.75rem"}}>Filled Jobs: </h1>
                                            {filledJobs.map((job) => (
                                                <div className="job_posting_holder">
                                                    <Link className = "job_posting_link" to={`/job/${job.id}`}>
                                                        <div className="job_posting">
                                                            <div class = "job_posting_info">
                                                                <h2> {job.jobTitle} </h2>
                                                                <span style={{fontWeight: "bold"}}> Location: </span>
                                                                <span>  {job?.remote ? 'Remote' : job?.location?.label}  </span>
                                                            </div>


                                                            <div className="end_of_posting">
                                                                <FaIcons.FaArrowRight />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            ))}
                                        </div>
                                    )}



                                </div>
                            )}



                            {
                                ((filteredJobs.length === 0) && (filledJobs.length === 0)) && (
                                    <div className="no_jobs_yet">
                                        <p> No jobs yet!</p>
                                    </div>
                                )
                            }
                        </>
                    )
                } */}









            </div>



            </div>


        </>

    );
};
