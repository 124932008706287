import { X } from "lucide-react";
import { useState } from "react";
import * as FaIcons from "react-icons/fa"
import Popup from "reactjs-popup";
import "./styles/emailHistory.css";
import { red } from "@mui/material/colors";
import ReactQuill from "react-quill";
import { auth, db } from "../firebase";
import { Timestamp, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useEffect } from "react";

function dateToString(date) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });

    // Get the correct suffix for the day
    const daySuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // for 4-20
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    };

    return `${day}${daySuffix(day)} ${month}`;
}

export const placeholders = ["{{candidate_name}}", "{{employer_name}}", "{{job_title}}"];

export const Placeholder = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const copyText = async () => {
        try {
            await navigator.clipboard.writeText(text);
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <div className="placeholder" onClick={copyText}>
            {copied ? 'Copied!' : text}
        </div>
    );
};


export const CandidateEmailHistory = ({
    candidate, 
    candidateId, 
    emailHistory, 
    employer, 
    job, 
    additionalClass, 
    page,
    popupIsOpen,
    setPopupOpen
}) => {

    const [sendEmailPopupIsOpen, setSendEmailPopupOpen] = useState(false);

    const [employerData, setEmployerData] = useState(employer);

    const [canRun, setCanRun] = useState(false);

    const [currentEmailHistory, setCurrentEmailHistory] = useState(emailHistory);


    const sendCandidateEmail = async () => {
        
        fetch('https://api.skillpool.tech/send_email', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            senderEmail: candidate?.employerEmail,
            receiverEmail: candidate?.email,
            subject: newEmailSubject,
            body: newEmailBody,
            candidateID: candidateId,
        }),
        // Include headers if your server requires them
        });

        let body = newEmailBody; 
        
        let subject = newEmailSubject;


        subject = subject.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        subject = subject.replace(/{{job_title}}/g, job?.jobTitle);
        subject = subject.replace(/{{employer_name}}/g, employer.name);

        body = body.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        body = body.replace(/{{job_title}}/g, job?.jobTitle);
        body = body.replace(/{{employer_name}}/g, employer.name);

        const time = Timestamp.fromDate(new Date());

        const newEmailHistoryEntry = {
            sent: true,
            time: time,
            subject: subject,
            body: body
          };
      
        //   // Update the emailHistory array
        const docRef = doc(db, "candidates", candidateId);


        const newEmailHistory = [...currentEmailHistory, newEmailHistoryEntry];

        setCurrentEmailHistory(newEmailHistory)

        setNewEmailBody("");
        setNewEmailSubject("");
        setSendEmailPopupOpen(false)
    
    }

    const [newEmailBody, setNewEmailBody] = useState("");
    const handleEmailBodyChange = (content, delta, source, editor) => {
        setNewEmailBody(editor.getHTML()); // or content for just the HTML content
    };

    const [newEmailSubject, setNewEmailSubject] = useState("");







    // Handling teh email template aspect

    const loadTemplate = (templateKey) => {
        let subject, body;
    
        switch (templateKey) {
            case 'no_update':
                subject = employerData.settings.email_templates.no_update.subject;
                body = employerData.settings.email_templates.no_update.body;
                break;
            case 'default':
                subject = "";
                body = "";
                break;
            default:
                subject = employerData.settings.email_templates.custom[parseInt(templateKey)].subject;
                body = employerData.settings.email_templates.custom[parseInt(templateKey)].body;
                // this means that it's an index of the custom emails
                break;
        }

        subject = subject.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        subject = subject.replace(/{{job_title}}/g, job?.jobTitle);
        subject = subject.replace(/{{employer_name}}/g, employer.name);

        body = body.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        body = body.replace(/{{job_title}}/g, job?.jobTitle);
        body = body.replace(/{{employer_name}}/g, employer.name);
    
        setNewEmailSubject(subject);
        setNewEmailBody(body);
    };


    const closeSendEmail = () => {
        setNewEmailBody('');
        setNewEmailSubject('');
        setSendEmailPopupOpen(false);
    }


    return (
        <Popup
            trigger={
                <div className={`candidate_email ${additionalClass}`}>
                    <FaIcons.FaRegEnvelope className="candidate_email_icon" />
                    <span className="candidate_email_text" style={{marginRight:"1rem"}}>{candidate?.email}</span>

                    <FaIcons.FaExpandAlt />
                </div>
            }
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder">
                <div className="popup">
                    <h1 className="popup_heading">Email History</h1>
                    <div className="popup_close" onClick={(e) => setPopupOpen(false)}>
                        <X />
                    </div>

                    
                    <div className="email_history">
                        {currentEmailHistory.map((email, index) => {
                            const emailClassName = email.sent
                            ? "email_history_sent_item "
                            : "email_history_received_item";
                            const date = (new Date(email.time?.seconds * 1000));
                            return (
                                <div key={index} className={emailClassName}>
                                    <div className="email_history_item">
                                        <div className="email_history_item_top" style={{marginBottom:"0.5rem"}}>
                                            <div className="email_history_item_name">    
                                                <h3>{
                                                    emailClassName === "email_history_received_item" ? candidate?.name : employer?.name                                                
                                                }</h3>
                                            </div>
                                            <div className="email_history_item_date">{dateToString(date)}</div>
                                        </div>
                                        <div className="email_history_item_subject" style={{marginBottom:"0.5rem"}}><b>{email.subject} </b></div>
                                        <div className="email_history_item_body" dangerouslySetInnerHTML={{__html: email.body}} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <Popup trigger={
                        <div className="candidate_send_email">
                            Send Email
                        </div>
                    }
                    open={sendEmailPopupIsOpen}
                    onOpen={() => setSendEmailPopupOpen(true)}
                    modal
                    nested
                    position="center center"
                    >
                        <div className="popup_holder second">
                            <div className="popup">
                                <h1 className="popup_heading" style={{marginBottom:"1rem"}}>Send Email</h1>

                                <div className="candidate_send_email_description" style={{display: "flex", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
                                    <p style={{width:"70%"}}>Go ahead and enter the details for the email, or choose from one of your templates to the right. </p>
                                    <div className="send_email_template">
                                        <select className="send_email_select" onChange={(e) => loadTemplate(e.target.value)}>
                                            <option value="default" selected >Load Template</option>
                                            <option value="no_update">No Update</option>
                                            {employer && employer.settings.email_templates.custom.map((template, index) => (
                                                <option value={index} key={index}>{template.name}</option>
                                            ))}
                                            {/* now run a map through all of the custom emails */}
                                        </select>
                                    </div>
                                </div>

                                

                                <div className="candidate_send_email_holder">
                                    <div className="candidate_send_email_details">

                                        <h3>Subject: </h3>
                                        <input type="text" placeholder="Subject" value={newEmailSubject} onChange={(e) => setNewEmailSubject(e.target.value)} className="send_email_subject" />

                                        <h3>Body: </h3>
                                        <ReactQuill theme="snow" value={newEmailBody} onChange={handleEmailBodyChange} placeholder="Please enter the body here..." className="send_email_body"/>
                                    </div>

                                    {/* <div className="candidate_send_email_placeholders">
                                        <h3>Usable Placeholders</h3>
                                        <div className="placeholders_list">
                                            {placeholders.map((text, index) => (
                                                <Placeholder key={index} text={text} />
                                            ))}
                                        </div>
                                    </div> */}
                                </div>
                               

                                <div className="popup_bottom_actions">
                                    <div className="popup_cancel" onClick={closeSendEmail}>
                                        Cancel
                                    </div>

                                    <div className="popup_action" onClick={sendCandidateEmail}>
                                        Send
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Popup>


                </div>
            </div>
        </Popup>
    );
}