// File will contain the routing logic for the app
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NotFound } from "./pages/NotFound"; 
import { Job } from "./pages/job";
import { Dashboard } from "./pages/dashboard";
import { AllJobs } from "./pages/all_jobs";
import { Candidate } from "./pages/candidate";
import Sidebar from "./components/Sidebar";
import { Candidates } from "./pages/job_subPages/candidates";
import { Pipeline } from "./pages/job_subPages/pipeline";
import { Settings } from "./pages/settings";
import { AllCandidates } from "./pages/AllCandidates";
import { CreateJob } from "./pages/createJob";
import { Notification } from "./components/notification";
import { Team } from "./pages/team";
import { Members } from "./pages/team_subpages/members";
import { Roles } from "./pages/team_subpages/roles";
import { CalendarShowcase } from "./pages/calendar";
import { CalSettings } from "./pages/calendar_settings";
import { CandidateSwitch } from "./pages/candidate_switch";
import { JobSettings } from "./pages/job_subPages/job_settings";
import { JobDashboard } from "./pages/job_subPages/job_dashboard";
import { PipelineEditor } from "./components/pipelineEditor";
import { SettingsAccount } from "./pages/settings_subPages/account";
import { SettingsEmails } from "./pages/settings_subPages/emails";
import { SettingsJobsPage } from "./pages/settings_subPages/jobs_page";
import { Admin } from "./pages/admin";
import { ConfirmLinks } from "./pages/confirmLinks";
import { ScheduleMeeting } from "./pages/scheduleMeeting";
import { Automation } from "./components/automation";
import { SettingsScheduling } from "./pages/settings_subPages/scheduling";
import { JobSourcing } from "./pages/job_subPages/sourcing";
import { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { permissions } from "./json/permissions";
import { Billing } from "./components/billing";
import { BillingSuccess } from "./components/billingSuccess";


export var globalPermissions = null;

export const Routing = () => {
    const [canRun, setCanRun] = useState(false);
    const [employerID, setEmployerID] = useState(null);
    const [employer, setEmployer] = useState(null);
    const [canAccess, setCanAccess] = useState(true);
    const [member, setMember] = useState(null);
  
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setCanRun(true);
            } else {
                setCanRun(false);
            }
        });
  
        return () => unsubscribe();
    }, []);
  
    useEffect(() => {
        const getTeam = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                    throw new Error('No authenticated user found');
                }
  
                const membersRef = collection(db, "members");
                const qMember = query(membersRef, where("email", "==", user.email));
                const memberSnapshot = await getDocs(qMember);
                if (memberSnapshot.empty) {
                    throw new Error('Member not found');
                }
  
                const memberData = memberSnapshot.docs[0].data();
                setMember({
                    id: memberSnapshot.docs[0].id,
                    ...memberData
                });
                globalPermissions = permissions[memberData.role];
                const { team_id } = memberData;
                setEmployerID(team_id);

                const teamsRef = doc(db, "teams", team_id);
                const teamSnapshot = await getDoc(teamsRef);
                if (teamSnapshot.empty) {
                    throw new Error('Team not found');
                }
                const teamData = teamSnapshot.data();
                setEmployer(teamData);

                const currentPath = window.location.pathname;

                if (currentPath !== "/billing/success") {
                    if (teamData.createdTime && !teamData.advisor) {
                        const twoWeeksAgo = new Date();
                        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
                        
                        if (teamData.createdTime.toDate() < twoWeeksAgo) {
                            if (teamData.billing && teamData.billing.subscription_ends) {
                                const currentTime = new Date();
                                const subscriptionEndTime = teamData.billing.subscription_ends.toDate();
                                
                                if (currentTime > subscriptionEndTime) {
                                    setCanAccess(false);
                                }
                            } else {
                                setCanAccess(false);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error("Failed to fetch user data", error);
            }
        };
  
        if (employerID === null && canRun) {
            getTeam();
        }
    }, [canRun, employerID]);

    const activePermissions = member?.role ? permissions[member.role] : null;

  
    return (
        <>
        <div className="website-container">
            <Router>
                {
                    !canAccess && <Billing />
                }   
                <Sidebar />
                <div className="main_content">
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/login" element={<Dashboard />} />
                        <Route path="/team" element={<Team />}>
                            <Route path="members" element={<Members />} />
                            <Route path="roles" element={<Roles />} />
                        </Route>
                        <Route path="job/:jobId" element={<Job />}>
                            <Route path="" element={<JobDashboard />} />
                            <Route path="candidates" element={<Candidates />} />
                            <Route path="pipeline" element={<Pipeline />} />
                            <Route path="settings" element={<JobSettings />} />
                            <Route path="sourcing" element={<JobSourcing />} />
                        </Route>
                        <Route path="jobs" element={<AllJobs />} />
                        <Route path="jobs/new" element={<CreateJob />} />
                        <Route path="candidate/:candidateId" element={<Candidate />} />
                        <Route path="job/:jobId/candidate/:candidateId" element={<Candidate />} />
                        <Route path="job/:jobId/candidate/:candidateId/switch" element={<CandidateSwitch />} />
                        <Route path="schedule/:id" element={<ScheduleMeeting />} />
                        <Route path="settings" element={<Settings />}>
                            <Route path="" element={<SettingsAccount />} />
                            <Route path="emails" element={<SettingsEmails />} />
                            <Route path="scheduling" element={<SettingsScheduling />} />
                            <Route path="jobs_page" element={<SettingsJobsPage />} />
                        </Route>
                        <Route path="candidates" element={<AllCandidates />} />
                        <Route path="calendar" element={<CalendarShowcase />} />
                        <Route path="calendar/settings" element={<CalSettings />} />
                        <Route path="billing/success" element={<BillingSuccess employerID={employerID} />} />
                        <Route path="pipelinetest" element={<PipelineEditor />} />
                        <Route path="oauth2callback" element={<CalSettings />} />
                        <Route path="admin" element={<Admin />} />
                        <Route path="confirmLinks" element={<ConfirmLinks />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </Router>
            <Notification />
            <Automation />

            
        </div>


       
        </>
    );
}
