import { Draggable } from "react-beautiful-dnd"
import * as FaIcons from "react-icons/fa"
import { stagesNames } from "../../job"
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { X } from "lucide-react";
import { auth, db } from "../../../firebase";
import { collection, query, where, getDocs, getDoc, doc, updateDoc  } from "firebase/firestore";
import { sendNotification } from "../../../components/notification";
import { Details } from "../../../components/pipeline_nodes/interviewingCategories/details";
import { Interviewers } from "../../../components/pipeline_nodes/interviewingCategories/interviewers";
import { Feedback } from "../../../components/pipeline_nodes/interviewingCategories/feedback";
import { Scheduling } from "../../../components/pipeline_nodes/interviewingCategories/scheduling";
import { Debrief } from "../../../components/pipeline_nodes/interviewingCategories/debrief";

export const EditInterview = ({
    index, 
    provided, 
    stage, 
    deletePipelineStage,
    pipeline, 
    setPipeline,
    job, 
    setJob, 
    employer
}) => {


    const [popupIsOpen, setPopupOpen] = useState(false);

    const openPopup = (event) => {
        setPopupOpen(true);
        event.preventDefault();
    }

    // handling the node subnav items
    const [activeSection, setActiveSection] = useState('details');




    // Handling loading from database

    const [canRun, setCanRun] = useState(false);
    const [employerID, setEmployerID] = useState(false);
    const [employerData, setEmployerData] = useState(null);

    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
            setCanRun(true);
        } else {
            setCanRun(false);
        }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
            const user = auth.currentUser;
            if (!user) {
                throw new Error('No authenticated user found');
            }

            const membersRef = collection(db, "members");
            const qMember = query(membersRef, where("email", "==", user.email));
            const memberSnapshot = await getDocs(qMember);
            if (memberSnapshot.empty) {
                throw new Error('Member not found');
            }

            const memberData = memberSnapshot.docs[0].data();
            const { team_id } = memberData;
            setEmployerID(team_id);

            const teamSnapshot = await getDoc(doc(db, "teams", team_id));
            if (!teamSnapshot.exists()) {
                throw new Error('Team not found');
            }

            const teamData = teamSnapshot.data();
            setEmployerData(teamData);

            let teamMembersIds = teamData.members.map(member => member);
            const teamMembersPromises = teamMembersIds.map(async id => {
                const memberSnap = await getDoc(doc(db, "members", id));
                if (!memberSnap.exists()) {
                console.warn(`Member with id ${id} not found, removing from team members.`);
                teamMembersIds = teamMembersIds.filter(memberId => memberId !== id);
                return null;
                } else {
                const memberData = memberSnap.data();
                return {
                    id: id,
                    ...memberData,
                };
                }
            });

            const teamMembers = (await Promise.all(teamMembersPromises)).filter(member => member !== null);

            if (teamMembersIds.length !== teamData.members.length) {
                await updateDoc(doc(db, "teams", team_id), { members: teamMembersIds });
            }

            const team = teamMembers;
            setTeamMembers(team);
            } catch (error) {
            console.error("Failed to fetch team members:", error);
            }
        };

        fetchTeamMembers();
    }, [canRun]);


    // handling the complete data for each of the sections

    const [complete, setComplete] = useState(stage?.complete || false);
    
    const [detailsComplete, setDetailsComplete] = useState(stage.detailsComplete || false);

   



    const [interviewersComplete, setInterviewersComplete] = useState(stage?.interviewers?.interviewers?.length > 0 || false);
    const [feedbackComplete, setFeedbackComplete] = useState(true);
    const [schedulingComplete, setSchedulingComplete] = useState(true);
    const [debriefComplete, setDebriefComplete] = useState(true);


    const saveInterview = async () => {
        const stage = {
            complete, 
            label: "interview",
            details: {
                name, 
                duration, 
                interviewType, 
                location, 
                inPersonLocation, 
                interviewNotes
            },
            interviewers: {
                interviewers: interviewers,
                hasEmails: hasEmails
            },
            feedback: {
                enabled: feedbackEnabled,
                remindersIn: feedbackRemindersIn,
                questions: selectedQuestions
            },
            scheduling: {
                automateScheduling,
                automatedSchedulingChoice,
                enableInterviewReminder,
                reminderTime
            },
            debrief: {
                enabled: debriefEnabled,
                debriefDuration,
                scheduleAutomatically: scheduleDebriefAutomatically,
                enableEmailReminder: sendEmailDebriefReminders,
                reminderTime: debriefEmailRemindersTime,
                debriefMembers,
            },
        }

        

        const updatedPipeline = [...pipeline];
        updatedPipeline[index] = stage;

        // setJob({...job, pipeline: updatedPipeline});
        setPipeline(updatedPipeline);
        setPopupOpen(false);
    }


    useEffect(() => {
        if (detailsComplete && interviewersComplete && feedbackComplete && schedulingComplete && debriefComplete) {
            setComplete(true);
        } else {
            setComplete(false);
        }
    }, [detailsComplete, interviewersComplete, feedbackComplete, schedulingComplete, debriefComplete]);



    // Handling all the stages' details

    // details

    const [name, setName] = useState(stage?.name || "Interview");
    const [duration, setDuration] = useState(stage?.details?.duration || 30);
    const [interviewType, setInterviewType] = useState(stage?.details?.interviewType || '');
    const [location, setLocation] = useState(stage?.details?.location || '');
    const [inPersonLocation, setInPersonLocation] = useState(stage?.details?.inPersonLocation || null);
    const [interviewNotes, setInterviewNotes] = useState(stage?.details?.interviewNotes || '');


    const [locationComplete, setLocationComplete] = useState(false);

    useEffect(() => {
        if (location) {
            if (location === "In-Person") {
                if (inPersonLocation) {
                    setLocationComplete(true);
                } else {
                    setLocationComplete(false);
                }
            } else {
                setLocationComplete(true);
            }
        } else {
            setLocationComplete(false);
        }
    }, [location, inPersonLocation])

    useEffect(() => {
        if (interviewType && locationComplete && interviewNotes && duration) {
            setDetailsComplete(true);
        } else {
            setDetailsComplete(false);
        }
    }, [interviewType, locationComplete, interviewNotes, duration])


    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleDurationChange = (e) => {
        const value = e.target.value;

        if (value < 0) {
        sendNotification("Please send a valid duration!");
        return;
        }

        if (value > 90) {
        sendNotification("Please enter a duration less than 90 minutes!");
        return;
        }

        if (value === "") {
        setDuration(0);
        return;
        }

        setDuration(parseInt(value));
    }

    const handleLocationChange = (type) => {
        setLocation(type);
    };


    // interviewing

    const [interviewers, setInterviewers] = useState(stage?.interviewers?.interviewers || []);
    const [hasEmails, setHasEmails] = useState(stage?.interviewers?.hasEmails || false);

    

    // feedback

    const [feedbackEnabled, setFeedbackEnabled] = useState(stage?.feedback?.enabled || false);
    const [feedbackRemindersIn, setFeedbackRemindersIn] = useState(stage?.feedback?.remindersIn || 0);
    
    const [selectedQuestions, setSelectedQuestions] = useState(stage?.feedback?.selectedQuestions || []);
    const [feedbackForms, setFeedbackForms] = useState(employer?.feedbackForms);

    useEffect(() => {
        setFeedbackForms(employer?.feedbackForms);
        setSelectedQuestions(stage?.feedback?.selectedQuestions);
    }, [employer]);

    const [selectedForm, setSelectedForm] = useState(null);

    // scheduling

    const [automateScheduling, setAutomateScheduling] = useState(stage?.scheduling?.automateScheduling || false);
    const [automatedSchedulingChoice, setAutomatedSchedulingChoice] = useState(stage?.scheduling?.automatedSchedulingChoice || null);

    useEffect(() => {
        if (hasEmails) {
            if (automatedSchedulingChoice === "direct") {
                sendNotification("Can't choose direct booking when using external interviewers");
                setAutomatedSchedulingChoice("availability");
            }
        }
    }, [automatedSchedulingChoice, hasEmails])

    const [enableInterviewReminder, setEnableInterviewReminder] = useState(stage?.scheduling?.enableInterviewReminder || false);
    const [reminderTime, setReminderTime] = useState(stage?.scheduling?.reminderTime || 24);


    // debrief

    const [debriefEnabled, setDebriefEnabled] = useState(stage?.debrief?.enabled || false);

    const [debriefDuration, setDebriefDuration] = useState(stage?.debrief?.duration || 30);

    const [scheduleDebriefAutomatically, setScheduleDebriefAutomatically] = useState(stage?.debrief?.scheduleAutomatically || false);

    const [sendEmailDebriefReminders, setSendEmailDebriefReminders] = useState(stage?.debrief?.sendEmailReminders || false);

    const [debriefEmailRemindersTime, setDebriefEmailRemindersTime] = useState(stage?.debrief?.emailRemindersTime || 24);

    const [debriefMembers, setDebriefMembers] = useState(stage?.debrief?.members || []);


    useEffect(() => {
        // set all values to the stage
        
        setComplete(stage?.complete || false);

        // details values

        setName(stage?.details?.name || "Interview");
        setDuration(stage?.details?.duration || 30);
        setInterviewType(stage?.details?.interviewType || '');
        setLocation(stage?.details?.location || '');
        setInPersonLocation(stage?.details?.inPersonLocation || null);
        setInterviewNotes(stage?.details?.interviewNotes || '');

        // interviewers values

        setInterviewers(stage?.interviewers?.interviewers || []);
        setHasEmails(stage?.interviewers?.hasEmails || false);

        if (stage?.interviewers?.interviewers?.length > 0 ) {
            setInterviewersComplete(true);
        } else {
            setInterviewersComplete(false);
        }

        // feedback values

        setFeedbackEnabled(stage?.feedback?.enabled || false);
        setFeedbackRemindersIn(stage?.feedback?.remindersIn || 0);
        setSelectedQuestions(stage?.feedback?.selectedQuestions || []); 

        // scheduling values

        setAutomateScheduling(stage?.scheduling?.automateScheduling || false);
        setAutomatedSchedulingChoice(stage?.scheduling?.automatedSchedulingChoice || null);
        setEnableInterviewReminder(stage?.scheduling?.enableInterviewReminder || false);
        setReminderTime(stage?.scheduling?.reminderTime || 24);

        // debrief values

        setDebriefEnabled(stage?.debrief?.enabled || false);
        setDebriefDuration(stage?.debrief?.duration || 30);
        setScheduleDebriefAutomatically(stage?.debrief?.scheduleAutomatically || false);
        setSendEmailDebriefReminders(stage?.debrief?.sendEmailReminders || false);
        setDebriefEmailRemindersTime(stage?.debrief?.emailRemindersTime || 24);
        setDebriefMembers(stage?.debrief?.members || []);
        
        
    }, [stage]);



    return (
        <>
            <Draggable key={stage.id || index} draggableId={`stage-${index}`} index={index}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="edit_pipeline_stage"
                        onClick={openPopup}
                    >
                        <div className="stage_details" style={{display:"flex", alignItems:"center"}}>
                            <div style={{width: "2rem"}}>
                                {
                                    stage.label === "interview" && (
                                        <FaIcons.FaGripVertical style={{ cursor: 'grab' }} />
                                    )
                                }
                            </div>
                            <p style={{margin:0, marginRight:"0.5rem", width:"10rem"}}>{name || stagesNames[stage.label]}</p>

                            {
                                (stage.label === "applied" || stage.label === "hired" || stage.label === "made_offer") ? (
                                    <>
                                    </>
                            ) : (
                                <p style={{margin:0, opacity:0.5, fontWeight:"500"}}>Click to Edit</p>
                            )}
                        </div>
                        
                        <div className="stage_actions" style={{position:"relative", display:"flex", alignItems:"center"}}>
                            {!complete && (
                                <div className="edit_pipeline_stage_incomplete">
                                    !
                                </div>
                            )  }

                            {
                                ((stage.label === "applied" || stage.label === "hired" || stage.label === "made_offer" || stage.label === "skillscore")) ? (
                                    <>
                                    </>
                                ) : (
                                    <FaIcons.FaTrash className="stage_action_icon" onClick={(e) => {
                                        deletePipelineStage(index);
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }} />
                                )
                            }
                        </div>
                    </div>
                )}
            </Draggable>

            <Popup 
            open={popupIsOpen}
            onOpen={() => setPopupOpen(true)}
            modal
            nested
            position="center center"
            >
                <div className="popup_holder overlay">
                    <div className="popup">
                        <div className="top_section">
        {/* 
                        <div className="interview_heading">
                        <FaIcons.FaPen />
                        <input className="create_stage_heading input" value={name} onChange={handleNameChange} />
                        </div> */}

                        <h1 className="create_stage_heading" style={{marginBottom:"2rem", minHeight: "1.2em"}} >{name || "\u00A0"}</h1>               

                        <div className="popup_close" onClick={saveInterview}>
                        <X />
                        </div>

                        <div className="interview_node_switcher subnav">
                            <div className={`subnav_item ${activeSection === 'details' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('details')}>
                                <p>Details</p>

                                {!detailsComplete && (
                                    <div className="interview_section_incomplete">
                                        !
                                        {/* Note that this will actually be an orange circle / symbol.  */}
                                    </div>
                                )}

                            </div>
                            <div className={`subnav_item ${activeSection === 'interviewers' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('interviewers')}>
                                <p>Interviewers</p>

                                {!interviewersComplete && (
                                    <div className="interview_section_incomplete">
                                        !
                                        {/* Note that this will actually be an orange circle / symbol.  */}
                                    </div>
                                )}
                            </div>
                            <div className={`subnav_item ${activeSection === 'feedback' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('feedback')}>
                                <p>Feedback</p>

                                {!feedbackComplete && (
                                    <div className="interview_section_incomplete">
                                        !
                                        {/* Note that this will actually be an orange circle / symbol.  */}
                                    </div>
                                )}

                            </div>
                            <div className={`subnav_item ${activeSection === 'scheduling' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('scheduling')}>
                                <p>Scheduling</p>

                                {!schedulingComplete && (
                                    <div className="interview_section_incomplete">
                                        !
                                        {/* Note that this will actually be an orange circle / symbol.  */}
                                    </div>
                                )}
                            </div>
                            <div className={`subnav_item ${activeSection === 'debrief' ? 'active_subnav_item' : ''}`} onClick={() => setActiveSection('debrief')}>
                                <p>Debrief</p>

                                {!debriefComplete && (
                                    <div className="interview_section_incomplete">
                                        !
                                        {/* Note that this will actually be an orange circle / symbol.  */}
                                    </div>
                                )}
                            </div>
                        </div>

                        {activeSection === 'details' && (
                            <Details 
                            name={name}
                            handleNameChange={handleNameChange}
                            interviewType={interviewType}
                            setInterviewType={setInterviewType}
                            duration={duration}
                            handleDurationChange={handleDurationChange}
                            location={location}
                            handleLocationChange={handleLocationChange}
                            inPersonLocation={inPersonLocation}
                            setInPersonLocation={setInPersonLocation}
                            interviewNotes={interviewNotes}
                            setInterviewNotes={setInterviewNotes}
                            complete={detailsComplete}
                            setComplete={setDetailsComplete}
                            />
                        )}
                        {activeSection === 'interviewers' && (
                            <Interviewers 
                            interviewers={interviewers}
                            setInterviewers={setInterviewers}
                            teamMembers={teamMembers}
                            setHasEmails={setHasEmails}
                            complete={interviewersComplete}
                            setComplete={setInterviewersComplete}
                            />
                        )}

                        {activeSection === 'feedback' && (
                            <Feedback 
                            enabled={feedbackEnabled}
                            setEnabled={setFeedbackEnabled}
                            feedbackRemindersIn ={feedbackRemindersIn}
                            setFeedbackRemindersIn={setFeedbackRemindersIn}
                            selectedQuestions={selectedQuestions}
                            setSelectedQuestions={setSelectedQuestions}
                            feedbackForms={feedbackForms}
                            setFeedbackForms={setFeedbackForms}
                            selectedForm={selectedForm}
                            setSelectedForm={setSelectedForm}      
                            complete={feedbackComplete}
                            setComplete={setFeedbackComplete}
                            />
                        )}
                        {activeSection === 'scheduling' && (
                            <Scheduling 
                            automateScheduling={automateScheduling}
                            setAutomateScheduling={setAutomateScheduling}
                            automatedSchedulingChoice={automatedSchedulingChoice}
                            setAutomatedSchedulingChoice={setAutomatedSchedulingChoice}
                            enableInterviewReminder={enableInterviewReminder}
                            setEnableInterviewReminder={setEnableInterviewReminder}
                            reminderTime ={reminderTime}
                            setReminderTime={setReminderTime}
                            employerData={employerData}
                            employerID={employerID}
                            hasEmails={hasEmails}
                            />
                        )}
                        {activeSection === 'debrief' && (
                            <Debrief 
                            enabled={debriefEnabled}
                            setEnabled={setDebriefEnabled}
                            debriefDuration={debriefDuration}
                            setDebriefDuration={setDebriefDuration}
                            scheduleAutomatically={scheduleDebriefAutomatically}
                            setScheduleAutomatically={setScheduleDebriefAutomatically}
                            sendEmailReminders={sendEmailDebriefReminders}
                            setSendEmailReminders={setSendEmailDebriefReminders}
                            emailRemindersTime={debriefEmailRemindersTime}
                            setEmailRemindersTime={setDebriefEmailRemindersTime}
                            complete={debriefComplete}
                            debriefMembers={debriefMembers}
                            setDebriefMembers={setDebriefMembers}
                            setComplete={setDebriefComplete}
                            index={index}
                            />
                        )}

                        <div className="stage_bottom_actions interview">
                        <div className="stage_delete interview" onClick={(e) => deletePipelineStage(index)}>
                            Delete
                        </div>

                        <div className="stage_save interview" onClick={saveInterview}>
                            Save
                        </div>
                        </div>


                        </div>
                    </div>
                </div>

            </Popup>
        </>
    )
}