import { deleteDoc, doc, updateDoc } from "firebase/firestore"
import { useState } from "react"
import Popup from "reactjs-popup"
import { db } from "../../firebase"
import { sendNotification } from "../notification"
import * as FaIcons from "react-icons/fa"

export const DeleteMember = ({member, currentMember, teamMembers, setTeamMembers, teams, setTeams, employerID}) => {


    const [popupIsOpen, setPopupOpen] = useState(false)

    const closePopup = () => {
        setPopupOpen(false);
        setDeleteMessage(false);
    }

    const openDeletePopup = () => {

        if (currentMember.role !== "Admin") {
            sendNotification("Only the admin can edit team members!");
            return;
        }

        if (member.role === "Admin") {
            sendNotification("Admin cannot be deleted!");
            return;
        }

        setPopupOpen(true);
    }

    const deleteMember = async () => {
        const memberRef = doc(db, "members", member.id);

        console.log(member);

        try {
            // must also delete the member from their team
            const updatedTeams = teams.map(team => {
                if (team.name === member.team) {
                    return {
                        ...team,
                        members: team.members.filter(m => m.id !== member.id)
                    }
                }
                return team;
            });

            console.log(updatedTeams);

            const employerDocRef = doc(db, "teams", employerID);
            await updateDoc(employerDocRef, {
                teams: updatedTeams
            });

            await deleteDoc(memberRef); // Ensure deletion completes before proceeding
            setTeams(updatedTeams);
            const updatedTeamMembers = teamMembers.filter((teamMember) => teamMember.id !== member.id);
            setTeamMembers(updatedTeamMembers);

            setDeleteMessage(true); // Show deleting message

            // Wait for 1500ms before closing the popup
            setTimeout(() => {
                setDeleteMessage(false);
                closePopup(); // Close the popup after all operations are complete
            }, 1500);
        } catch (error) {
            sendNotification("Failed to delete member."); // Handle potential errors
        }
    };

    const [deleteMessage, setDeleteMessage] = useState(false);

    return (
        <>
            <div className='member_action' onClick={openDeletePopup}>
                <FaIcons.FaTrash />
            </div>

            <Popup 
            open={popupIsOpen}
            modal
            nested
            position="center center"
            >   
                <div className="popup_holder">
                    <div className='popup' style={{width: "30%", height:"35%"}}>
                        <h1 className="popup_heading">Delete Team Member</h1>

                        {
                            deleteMessage && 
                            <p>{member.first_name} {member.last_name} has been removed from your team!</p>
                        }


                        {
                            !deleteMessage && 
                            <>
                                <p>Are you sure you want to delete {member.first_name} {member.last_name} from your team?</p>

                                <div className='actions_bottom'>
                                    <div className='close_popup' onClick={closePopup}>Close</div>

                                    <div className='create_member delete_member' onClick={deleteMember}>
                                        Delete
                                    </div>
                                </div>
                            </>
                        }

                        
                    </div>
                </div>

            </Popup>
        </>
    )
}