import { React, useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { stagesNames } from "../pages/job";
import "./styles/shiftCandidateStage.css"
import ReactQuill from "react-quill";
import * as FaIcons from "react-icons/fa";
import { X } from "lucide-react";
import { HiSparkles } from "react-icons/hi2";
import { Timestamp, addDoc, arrayUnion, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import { diffChars } from 'diff';
import { sendNotification } from "./notification";
import { ScheduleInterviewPopup } from "./scheduleInterview";
import { sendAutomation } from "./automation";


export const ShiftCandidateStage = ({candidate, job, employerData}) => {

    const [popupIsOpen, setPopupOpen] = useState(false);

    const pipeline = job.pipeline;

    const [candidateActions, setCandidateActions] = useState(["Reject Candidate", "Move to next stage"]);

    const [selectedAction, setSelectedAction] = useState('');


    const sendCandidateEmail = (link = null) => {

        if (link) {
            fetch('https://api.skillpool.tech/send_email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    senderEmail: candidate?.employerEmail,
                    receiverEmail: candidate?.email,
                    subject: emailSubject,
                    body: emailBody,
                    candidateID: candidate.id,
                    link: link,
                }),
                // Include headers if your server requires them
            });
        } else {
            fetch('https://api.skillpool.tech/send_email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    senderEmail: candidate?.employerEmail,
                    receiverEmail: candidate?.email,
                    subject: emailSubject,
                    body: emailBody,
                    candidateID: candidate.id,
                }),
                // Include headers if your server requires them
            });
        }

    }

    const getCandidateActions = () => {
        if (candidate?.stage !== undefined) {

            var rejectionEnabled = true;
            var sendCandidateOffer = false; //Basically each of these stand for one of the actions, and the idea behind this useEffect is to see which ones are viable
            var manualMoving = true; //if the candidate is to be moved manually to the next stage
            var reconsiderCandidate = false;
            var hireCandidate = false;
            const currentStage = pipeline[candidate?.stage]?.label;

            let nextStage;

            if (candidate.stage !== pipeline.length - 1) {
                nextStage = pipeline[candidate.stage + 1].label;
            }

            // Now the options for the current stage are applied, prescreening, skillscore, interview, and made offer

            // Each of these will have thier own actions. Applied only allows rejecting, same w pre-screening, skillscore should
            // allow moving ahead or rejecting, and same with the interview (if the node after the interview one is made offer). Made offer should allow nothing

            if (candidate?.stage === -1) {
                // This means the candidate is rejected

                rejectionEnabled = false;
                manualMoving = false;
                reconsiderCandidate = true;
            }

            if (currentStage === "made_offer") {
                rejectionEnabled = false;
            }

            if ((nextStage === "prescreening") || (nextStage === "skillscore")) {
                manualMoving = false;
            }

            if (nextStage === "made_offer") {
                sendCandidateOffer = true;
                manualMoving = false;
            }


            if (nextStage === "hired") {
                hireCandidate = true;
                manualMoving = false;
                rejectionEnabled = true;
                // A candidate will go from made offer to hired automatically when they accept the offer.

                // What happens after that though, god knows.
            }

            if (currentStage === "hired") {
                manualMoving = false;
                rejectionEnabled = false;
                sendCandidateOffer = false;
                hireCandidate = false;
            }

            // Now determining the actions based on the vars.

            const hireAction = {
                message: "Hire Candidate",
                value: "hire",
            }

            const rejectionAction = {
                message: "Reject Candidate",
                value: "reject",
            }

            const manualMoveAction = {
                message: "Move Candidate to " + (pipeline[candidate?.stage + 1]?.details?.name || stagesNames[nextStage]),
                value: "move",
                to: nextStage,
            }

            const sendOffer = {
                message: "Send Candidate an Offer",
                value: "make_offer",
            }

            const reconsider = {
                message: "Reconsider Candidate",
                value: "reconsider",
            }


            const newCandidateActions = []

            if (rejectionEnabled) {
                newCandidateActions.push(rejectionAction);
            }

            if (manualMoving) {
                newCandidateActions.push(manualMoveAction);
            }

            if (sendCandidateOffer) {
                newCandidateActions.push(sendOffer);
            }

            // if (reconsiderCandidate) {
            //     newCandidateActions.push(reconsider);
            // }

            if (hireCandidate) {
                newCandidateActions.push(hireAction);
            }

            setCandidateActions(newCandidateActions);
        }
    }

    useEffect(() => {
        getCandidateActions();
    }, [candidate])


    const processEmail = (subject, body) => {

        subject = subject.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        subject = subject.replace(/{{job_title}}/g, job?.jobTitle);
        subject = subject.replace(/{{employer_name}}/g, employerData.name);

        body = body.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        body = body.replace(/{{job_title}}/g, job?.jobTitle);
        body = body.replace(/{{employer_name}}/g, employerData.name);

        return [subject, body]
        // This function is to process the email - removing the placeholders from the text and replacing it with the actual vals
    }

    // handling the action popup

    const [actionPopupOpen, setActionPopupOpen] = useState(false);

    // handling the scheduling popup

    const [schedulingPopupOpen, setSchedulingPopupOpen] = useState(false);


    const processSelectedAction = (selectedAction) => {


        setSelectedAction(selectedAction);

        if (selectedAction !== "") {
            const popup_actions = document.getElementById("shift_stage_actions");
            if (popup_actions) {
                popup_actions.style.justifyContent = "space-between";
            }

            if((pipeline[candidate.stage + 1].label === "interview") && (selectedAction.value === "move")) {
                setSchedulingPopupOpen(true);
            } else {
                setActionPopupOpen(true);
            }
            
        } else {
            const popup_actions = document.getElementById("shift_stage_actions");
            if (popup_actions) {
                popup_actions.style.justifyContent = "center";
            }
            return;
        }

        switch (selectedAction.value) {
            case "reject":
                // Load the rejection body and subject
                const emailData = processEmail(employerData.settings.email_templates.rejection.default.subject, employerData.settings.email_templates.rejection.default.body)

                setEmailSubject(emailData[0]);
                setEmailBody(emailData[1])


                break;

            case "move":

                switch (selectedAction.to) {
                    case "interview":
                        
                        moveToInterview();
                        // for now interview is the only case for move. Mayb more in the future, mayb i just map it by current state?
                        const emailData = processEmail(employerData.settings.email_templates.interview_invite.subject, employerData.settings.email_templates.interview_invite.body)
                        setEmailSubject(emailData[0]);
                        setEmailBody(emailData[1])
                        break;
                }

                break;

            case "send_offer":
                break;
        }
    }


    const closePopup = () => {
        setPopupOpen(false);
        setSelectedAction("");
    }

    const closeActionPopup = () => {
        setActionPopupOpen(false);
        setEmailBody('');
        setEmailSubject('');
        setSelectedAction("");
    }



    // Handling the various candidate actions to take

    const [showEmail, setShowEmail] = useState(false);
    const [showRejection, setShowRejection] = useState(false);

    const [emailSubject, setEmailSubject] = useState('')
    const [emailBody, setEmailBody] = useState('');

    const handleEmailBodyChange = (content, delta, source, editor) => {
        if (source === 'user') {
            setEmailBody(editor.getHTML()); // or content for just the HTML content
        }
    };

  


    const rejectCandidate = async () => {


        if (emailBody === '') {
            sendNotification("Please enter an email body")
            return;
        }

        if (emailSubject === '') {
            sendNotification("Please enter an email subject")
            return;
        }
        // Send the email

        sendCandidateEmail();

    
        const historyItem = {
            type: "stage",
            from: pipeline[candidate.stage]?.details?.name || stagesNames[pipeline[candidate.stage].label],
            to: "Rejected",
            time: Timestamp.now(),
        }

        candidate.stage = -1;
        try {
            const candidateRef = doc(db, "candidates", candidate.id);
            await updateDoc(candidateRef, {
                stage: -1,
                history: arrayUnion(historyItem),
            });
        } catch (error) {
            console.error("Error rejecting candidate:", error);
        }

        setActionPopupOpen(false);
        closePopup();
    }

    const automateSchedulingEmail = async (schedulingInfo) => {

        candidate.scheduled = true;
        let emailSubject;
        let emailBody;

        if (schedulingInfo.automatedSchedulingChoice === "direct") {
            // get the emails data
            emailSubject = employerData.settings.email_templates.interviews.directBooking.subject;
            emailBody = employerData.settings.email_templates.interviews.directBooking.body;
        } else {
            emailSubject = employerData.settings.email_templates.interviews.availability.subject;
            emailBody = employerData.settings.email_templates.interviews.availability.body;
        }

        const meetingRef = collection(db, "meeting_invites");
        const meetingDoc = await addDoc(meetingRef, {
            candidateID: candidate.id,
            jobID: candidate.job_id,
            stage: candidate.stage,
            duration: job.pipeline[candidate.stage].duration || 30,
            type: schedulingInfo.automatedSchedulingChoice,
        });

        let meetingLink = `https://jobs.skillpool.tech/book-meeting/${meetingDoc.id}`;

        let emailData;

        emailData = {
            senderEmail: candidate.employerEmail,
            receiverEmail: candidate.email,
            subject: emailSubject,
            body: emailBody,
            candidateID: candidate.id,
            link: meetingLink,
        }

        fetch('https://api.skillpool.tech/send_email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
            // Include headers if your server requires them
        });

        try {
            const candidateRef = doc(db, "candidates", candidate.id);
            await updateDoc(candidateRef, {
                scheduled: true,
            });
        } catch (error) {
            console.error("Error updating candidate:", error);
        }

    }

    const moveToInterview = async () => {
        const currStage = candidate.stage;

        candidate.stage = currStage + 1;
        candidate.scheduled = false;

        if (pipeline[currStage + 1].label === "interview") {
            // check to see if the interview can be automated
            if (pipeline[currStage + 1]?.scheduling?.automateScheduling || false) {
                sendAutomation("Sent the scheduling email", () => {
                    automateSchedulingEmail(pipeline[currStage + 1].scheduling);
                }, 4000);
                closePopup();
            }
        }

        const historyItem = {
            type: "stage",
            from: pipeline[currStage].details?.name || stagesNames[pipeline[currStage].label],
            to: pipeline[currStage + 1].details?.name || stagesNames[pipeline[currStage + 1].label],
            time: Timestamp.now(),
        }

        try {
            const candidateRef = doc(db, "candidates", candidate.id);
            await updateDoc(candidateRef, {
                history: arrayUnion(historyItem),
                stage: currStage + 1,
                scheduled: false,
            });
        } catch (error) {
            console.error("Error moving candidate:", error);
        }

        getCandidateActions();


    }


    const moveCandidate = async () => {

        if (emailBody === '') {
            sendNotification("Please enter an email body")
            return;
        }

        if (emailSubject === '') {
            sendNotification("Please enter an email subject")
            return;
        }



        const currStage = candidate.stage;
        let meetingLink;

        // check if the current stage is skillscore. Then check if the interview has been completed (only if it's required).
        // If so, send a notification telling the recruiter that the candidate has not completed the stage yet.

      if (pipeline[currStage].label === "skillscore") {
        // first checking if the job requires it
        const softSkills = pipeline[currStage].softSkills;
        const personalityFit = pipeline[currStage].personalityFit;


        if (!((softSkills.rating === 0) && (personalityFit.rating === 0))) {
            if (!(candidate.soft_skills) || !(candidate.personality_traits)) {
                sendNotification(`${candidate.name} has not completed the screening interview yet`);
                return;
            }
            }
        }

        // check if the stage is an intervew (which is probably is )
        if (pipeline[currStage + 1].label === "interview") {
            // create the meeting_invites doc

            const meetingRef = collection(db, "meeting_invites");
            const meetingDoc = await addDoc(meetingRef, {
                candidateID: candidate.id,
                jobID: candidate.job_id,
                stage: currStage + 1,
                duration: pipeline[currStage + 1].duration || 30,
            });

            meetingLink = `https://jobs.skillpool.tech/book-meeting/${meetingDoc.id}`;
        }

        if (meetingLink) {
            sendCandidateEmail(meetingLink);
        } else {
            sendCandidateEmail();
        }

        candidate.stage = currStage + 1;

        try {
            const candidateRef = doc(db, "candidates", candidate.id);
            await updateDoc(candidateRef, {
                stage: currStage + 1
            });
        } catch (error) {
            console.error("Error moving candidate:", error);
        }

        setActionPopupOpen(false);
        closePopup();
    }


    const hireCandidate = async () => {
        // changes the candidate stage and adds a hiredTime variable to the candidate

        if (emailBody !== '' && emailSubject === '') {
            sendNotification("Please enter an email subject")
            return;
        } else if (emailBody === '' && emailSubject !== '') {
            sendNotification("Please enter an email body")
            return;
        }

        // also sets the job to filled and adds a filledTime variable to the job
        const currStage = candidate.stage;
        candidate.stage = currStage + 1;

        // if either of the email body and subject are there, the other should be there. If none are there, we don't send email


        if (emailBody !== '' && emailSubject !== '') {
            sendCandidateEmail();
        }


        try {
            const candidateRef = doc(db, "candidates", candidate.id);
            await updateDoc(candidateRef, {
                stage: currStage + 1,
                hiredTime: Timestamp.fromDate(new Date())
            });

            const jobRef = doc(db, "jobs", candidate.job_id);
            await updateDoc(jobRef, {
                filled: true,
                filledTime: Timestamp.fromDate(new Date())
            });
        } catch (error) {
            console.error("Error hiring candidate:", error);
        }

        setActionPopupOpen(false);
        closePopup();
    }



    // Handling the personalized rejections

    const [personalizeRejectionPopupOpen, setPersonalizeRejectionPopupOpen] = useState(false);

    const closePersonalizePopup = () => {
        setPredictedReason('');
        setRejectionMessage('');
        setPersonalizeRejectionPopupOpen(false);
        setSuggestedBody('');
    }

    const [predictedReason, setPredictedReason] = useState('');

    const [rejectionMessage, setRejectionMessage] = useState('');

    const [suggestedBody, setSuggestedBody] = useState('');

    // Helper function to get the rejection message

    function getDifferences(str1, str2) {


        const diff = diffChars(str1, str2);

        let differences = '';

        diff.forEach(part => {
            if (part.added) {
                differences += part.value;
            }
        });

        return differences;
    }

    const personalizeRejection = async () => {

        setPersonalizeRejectionPopupOpen(true);

        // Get all candidates with the same job id from firebase collections candidates

        const getCandidates = async (jobId) => {
            const candidatesRef = collection(db, "candidates");
            const q = query(candidatesRef, where("job_id", "==", jobId));
            const querySnapshot = await getDocs(q);
            const candidates = [];
            querySnapshot.forEach((doc) => {
                candidates.push(doc.data());
            });
            return candidates;
        };

        const candidates = await getCandidates(candidate.job_id);

        const otherCandidates = candidates
        .filter(
            (cand) => cand.id !== candidate.id
        )
        .filter(
            // filter to ensure that they are not already rejected (stage = -1)
            (cand) => cand.stage !== -1
        )
        ;

        const skillscore = job.pipeline.find(stage => stage.label === "skillscore");


        // Finding average work history score.

        const workHistoryScores = otherCandidates.map((cand) => cand.work ? cand.work.score : 0);

        // get the average of these scores

        let bestWorkHistoryScore = 0;

        if (workHistoryScores.length > 0) {
            bestWorkHistoryScore = Math.max(...workHistoryScores);
        }

        // Now for education

        const educationScores = otherCandidates.map((cand) => cand.education ? cand.education.score : 0);

        let bestEducationScore = 0;

        if (educationScores.length > 0) {
            bestEducationScore = Math.max(...educationScores);
        }

        // Now for technical skills
        let bestTechnicalScore = 0;

        if (candidate.technical_skills?.score !== undefined) {

            const technicalScores = otherCandidates.map((cand) => cand.technical_skills ? cand.technical_skills.score : 0);

            if (technicalScores.length > 0) {
                bestTechnicalScore = Math.max(...technicalScores);
            }

        }

        // Now for soft skills
        let bestSoftScore = 0;

        if (candidate.soft_skills?.score !== undefined) {
            const softScores = otherCandidates.map((cand) => cand.soft_skills ? cand.soft_skills.score : 0);

            if (softScores.length > 0) {
                bestSoftScore = Math.max(...softScores);
            }

        }

        // Now for personality
        let bestPersonalityScore = 0;

        if (candidate.personality_fit?.score !== undefined) {
            const personalityScores = otherCandidates.map((cand) => cand.personality_fit ? cand.personality_fit.score : 0);

            if (personalityScores.length > 0) {
                bestPersonalityScore = Math.max(...personalityScores);
            }

        }

        // Now getting the differences between the best and this candidates

        const workDifference = candidate.work.score - bestWorkHistoryScore;
        const educationDifference = candidate.education.score - bestEducationScore;
        let technicalDifference = 0;
        let softDifference = 0;
        let personalityDifference = 0;
        if (candidate.technical_skills?.score !== undefined) {
            technicalDifference = candidate.technical_skills.score - bestTechnicalScore;
        }
        if (candidate.soft_skills?.score !== undefined) {
            softDifference = candidate.soft_skills.score - bestSoftScore;
        }
        if (candidate.personality_fit?.score !== undefined) {
            personalityDifference = candidate.personality_fit.score - bestPersonalityScore;
        }



        // Now getting the weights for each of these to see the effect

        const weights = {
            work: skillscore.workHistory.rating,
            education: skillscore.educationHistory.rating,
            technical: skillscore.technicalSkills.rating,
            soft: skillscore.softSkills.rating,
            personality: skillscore.personalityFit.rating,
        }

        // Normalizing the weights

        const totalWeight = Object.values(weights).reduce((a, b) => a + b, 0);

        for (let key in weights) {
            weights[key] = weights[key] / totalWeight;
        }

        // Getting the weighted differences

        const weightedDifferences = {
            work: workDifference * weights.work,
            education: educationDifference * weights.education,
            technical: technicalDifference * weights.technical,
            soft: softDifference * weights.soft,
            personality: personalityDifference * weights.personality,
        }


        // Now, we need to identify which of these is the lease, and then provide a rejection based on that.

        const leastWeightedDifference = Object.keys(weightedDifferences).reduce((a, b) => weightedDifferences[a] < weightedDifferences[b] ? a : b);

        // We also need to get the highest because positivity

        const highestWeightedDifference = Object.keys(weightedDifferences).reduce((a, b) => weightedDifferences[a] > weightedDifferences[b] ? a : b);


        if (weightedDifferences[leastWeightedDifference] >= 0) {
            setPredictedReason("No Predicted Rejection Reason");
            return;
        }

        if (weightedDifferences[highestWeightedDifference] <= 0) {
        }

        let candidateWeakness = '';

        let candidateStrength = '';

        switch (leastWeightedDifference) {
            case "work":
                setPredictedReason("Work Experience")
                candidateWeakness = "Work Experience"
                break;
            case "education":
                setPredictedReason("Education")
                candidateWeakness = "Education"
                break;
            case "technical":
                // Looking through each technical skill for the other candidates
                const technicalSkills = candidate.technical_skills.skills;
                // Get the technical skill weights
                const technicalSkillWeights = skillscore.technicalSkills.skills.map((skill) => skill.rating);
                // Normalize the weights
                const totalTechnicalWeight = technicalSkillWeights.reduce((a, b) => a + b, 0);

                for (let i = 0; i < technicalSkillWeights.length; i++) {
                    technicalSkillWeights[i] = technicalSkillWeights[i] / totalTechnicalWeight;
                }

                const weightedDifferences = [];

                technicalSkills.forEach((skill, index) => {
                    // Get the total score from other candidates in this skill
                    const otherCandidatesTechnicalSkills = otherCandidates.map((cand) => ( cand.technical_skills ? cand.technical_skills.skills : []));
                    const otherCandidatesSkillScores = otherCandidatesTechnicalSkills.map((candSkills) => candSkills[index] ? candSkills[index].score : 0);
                    const otherCandidatesTotalScore = otherCandidatesSkillScores.reduce((a, b) => a + b, 0);
                    let averageSkillScore = 0;
                    if (otherCandidates.length > 0) {
                        averageSkillScore = otherCandidatesTotalScore / otherCandidates.length;
                    }
                    // Now get the differences from this candidates scores
                    const skillDifference = skill.score - averageSkillScore;
                    // Now get the weighted difference

                    weightedDifferences.push( skillDifference * technicalSkillWeights[index]);
                });

                // Now get the index of the skill with the least weighted difference

                const leastWeightedSkillIndex = weightedDifferences.reduce((iMax, x, i, arr) => x < arr[iMax] ? i : iMax, 0);

                candidateWeakness = technicalSkills[leastWeightedSkillIndex].name + " Skill Level";
                setPredictedReason(technicalSkills[leastWeightedSkillIndex].name + " Skill Level");
                break;
            case "soft":
                // Copying the logic for technical skills to soft skills
                const softSkills = candidate.soft_skills.skills;
                const softSkillWeights = skillscore.softSkills.skills.map((skill) => skill.rating);
                const totalSoftWeight = softSkillWeights.reduce((a, b) => a + b, 0);

                for (let i = 0; i < softSkillWeights.length; i++) {
                    softSkillWeights[i] = softSkillWeights[i] / totalSoftWeight;
                }

                const softWeightedDifferences = [];

                softSkills.forEach((skill, index) => {
                    const otherCandidatesSoftSkills = otherCandidates.map((cand) => ( cand.soft_skills ? cand.soft_skills.skills : []));
                    const otherCandidatesSoftSkillScores = otherCandidatesSoftSkills.map((candSkills) => candSkills[index] ? candSkills[index].score : 0);
                    const otherCandidatesSoftTotalScore = otherCandidatesSoftSkillScores.reduce((a, b) => a + b, 0);
                    let averageSoftSkillScore = 0;
                    if (otherCandidates.length > 0) {
                        averageSoftSkillScore = otherCandidatesSoftTotalScore / otherCandidates.length;
                    }
                    const softSkillDifference = skill.score - averageSoftSkillScore;
                    softWeightedDifferences.push( softSkillDifference * softSkillWeights[index]);
                });

                const leastWeightedSoftSkillIndex = softWeightedDifferences.reduce((iMax, x, i, arr) => x < arr[iMax] ? i : iMax, 0);

                candidateWeakness = softSkills[leastWeightedSoftSkillIndex].name + " Skill Level";
                setPredictedReason(softSkills[leastWeightedSoftSkillIndex].name + " Skill Level");
                break;
            case "personality":
                // Copying the logic for technical skills to personality traits
                const personalityTraits = candidate.personality_traits.traits;
                const personalityTraitWeights = skillscore.personalityTraits.traits.map((trait) => trait.rating);
                const totalPersonalityWeight = personalityTraitWeights.reduce((a, b) => a + b, 0);

                for (let i = 0; i < personalityTraitWeights.length; i++) {
                    personalityTraitWeights[i] = personalityTraitWeights[i] / totalPersonalityWeight;
                }

                const personalityWeightedDifferences = [];

                personalityTraits.forEach((trait, index) => {
                    const otherCandidatesPersonalityTraits = otherCandidates.map((cand) => ( cand.personality_traits ? cand.personality_traits.traits : []));
                    const otherCandidatesPersonalityTraitScores = otherCandidatesPersonalityTraits.map((candTraits) => candTraits[index] ? candTraits[index].score : 0);
                    const otherCandidatesPersonalityTotalScore = otherCandidatesPersonalityTraitScores.reduce((a, b) => a + b, 0);
                    let averagePersonalityTraitScore = 0;
                    if (otherCandidates.length > 0) {
                        averagePersonalityTraitScore = otherCandidatesPersonalityTotalScore / otherCandidates.length;
                    }
                    const personalityTraitDifference = trait.score - averagePersonalityTraitScore;
                    personalityWeightedDifferences.push( personalityTraitDifference * personalityTraitWeights[index]);
                });

                const leastWeightedPersonalityTraitIndex = personalityWeightedDifferences.reduce((iMax, x, i, arr) => x < arr[iMax] ? i : iMax, 0);

                candidateWeakness = personalityTraits[leastWeightedPersonalityTraitIndex].name + " Level";
                setPredictedReason(personalityTraits[leastWeightedPersonalityTraitIndex].name + " Level");
                break;
        }


        switch (highestWeightedDifference) {
            case "work":
                candidateStrength = "Work Experience"
                break;
            case "education":
                candidateStrength = "Education"
                break;
            case "technical":
                candidateStrength = "Technical Skills"
                break;
            case "soft":
                candidateStrength = "Soft Skills"
                break;
            case "personality":
                candidateStrength = "Personality Fit"
                break;
        }

        const response = await fetch('https://api.skillpool.tech/get_rejection_message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                currentBody: emailBody,
                reason: candidateWeakness,
                positive: candidateStrength,
            })
        });

        const newBody = await response.json();

        function removeHTMLTags(str) {
            return str.replace(/<\/?[^>]+(>|$)/g, "");
        }

        const reason = removeHTMLTags(getDifferences(emailBody, newBody));

        setRejectionMessage(reason);

        setSuggestedBody(newBody);

    }

    const acceptPersonalizedRejection = () => {
        setEmailBody(suggestedBody);
        setRejectionMessage('');
        setPredictedReason('');
        setPersonalizeRejectionPopupOpen(false);
    }




    // Handling teh email template aspect

    const loadTemplate = (templateKey) => {
        let subject, body;

        switch (templateKey) {
            case 'no_update':
                subject = employerData.settings.email_templates.no_update.subject;
                body = employerData.settings.email_templates.no_update.body;
                break;
            case 'default':
                subject = "";
                body = "";
                break;
            default:
                subject = employerData.settings.email_templates.custom[parseInt(templateKey)].subject;
                body = employerData.settings.email_templates.custom[parseInt(templateKey)].body;
                // this means that it's an index of the custom emails
                break;
        }

        subject = subject.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        subject = subject.replace(/{{job_title}}/g, job?.jobTitle);
        subject = subject.replace(/{{employer_name}}/g, employerData.name);

        body = body.replace(/{{candidate_name}}/g, candidate?.name.split(" ")[0]);
        body = body.replace(/{{job_title}}/g, job?.jobTitle);
        body = body.replace(/{{employer_name}}/g, employerData.name);

        setEmailSubject(subject);
        setEmailBody(body);
    };

    return (

        <Popup

        trigger={
            <div className="candidate_stage">
                <FaIcons.FaProjectDiagram style={{fontWeight:"500", marginRight:"1rem"}} className="candidate_email_icon" />
                <div>
                    <p style={{fontWeight:"550", fontSize:"1.1rem", margin:0, marginBottom:"0.35rem"}}>{candidate?.stage >= 0 ? pipeline[candidate?.stage]?.details?.name || stagesNames[pipeline[candidate?.stage]?.label] : "Rejected"}</p>
                
                    <div style={{display:"flex", alignItems:"center", opacity:0.6}}>
                        <span style={{marginRight:"0.5rem"}}>Click to change</span>
                        <FaIcons.FaExpandAlt />
                    </div>
                </div>
            </div>
        }
        open={popupIsOpen}
        onOpen={() => setPopupOpen(true)}
        modal
        nested
        position="center center"
        >
            <div className="popup_holder">
                <div className="popup" style={{width: '80%', textAlign:"left"}}>


                    <div className="candidate_current_stage">
                        {candidate?.stage >= 0 ? pipeline[candidate?.stage]?.details?.name || stagesNames[pipeline[candidate?.stage]?.label] : "Rejected"}
                    </div>

                    <h1 className="popup_heading">Change Stage</h1>

                    <div className="popup_close" onClick={closePopup}>
                        <X />
                    </div>


                    <div
                        className="candidate_change_stage_options"
                    >

                        {/* add a message if no actions are available */}

                        {candidateActions.length === 0 && (
                            <p>No actions are available at this stage!</p>
                        )}


                        {candidateActions?.map((action, index) => (
                            <>
                            <div key={index} onClick={(e) => processSelectedAction(action)} className="candidate_change_stage_option">
                                <p>{action?.message}</p>
                                {/* if the next stage is an interview, the icon should be differnet */}

                                {((pipeline[candidate.stage + 1]?.label === "interview") && (action.value === "move")) ? (
                                    <FaIcons.FaArrowRight />
                                ) : (
                                    <FaIcons.FaExpandAlt />
                                )} 



                            </div>
                            <Popup
                                // trigger={

                                // }
                                open={actionPopupOpen}
                                onOpen={() => setActionPopupOpen(true)}
                                modal
                                nested
                                position="center center"
                            >
                            <div className="popup_holder second" style={{backdropFilter:"none"}}>
                                <div className="popup" style={{width: '80%', textAlign:"left", border:"none", boxShadow:"none"}}>

                                    <h2 className="popup_heading">{selectedAction.message}</h2>

                                    <div style={{position: "absolute", top: "2rem", left: "3rem", cursor:"pointer", fontSize:"1.2rem"}} onClick={closeActionPopup}>
                                        <FaIcons.FaChevronLeft />
                                    </div>


                                    <div className="change_stage_body">

                                    {(selectedAction.value === "reject") && (
                                        <>
                                            <input type="text" placeholder="Subject" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} className="change_stage_email_subject" />

                                            <ReactQuill theme="snow" value={emailBody} onChange={handleEmailBodyChange} placeholder="Please enter the body here..." className="change_stage_email_body" id="change_stage_email_body"/>


                                            <Popup
                                                trigger={
                                                    <div style={{width: "100%", display:"flex", justifyContent:"center"}}>
                                                        <div className="change_email_personalize_rejection_holder">
                                                            <div className="change_email_personalize_rejection" onClick={personalizeRejection}>
                                                                <HiSparkles style={{marginRight: ".5rem"}} />
                                                                Personalize
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                open={personalizeRejectionPopupOpen}
                                                onOpen={personalizeRejection}
                                                modal
                                                nested
                                                position="center center"
                                            >
                                                <div className="popup_holder" style={{}}>
                                                    <div className="popup" style={{border: "none", width: "60%", height: "50%"}}>
                                                        {/* <div className="popup_close" onClick={() => setPersonalizeRejectionPopupOpen(false)}>
                                                            <X />
                                                        </div> */}

                                                        <h2 className="popup_heading">Personalize Rejection</h2>

                                                        <div className="popup_body">

                                                            <div className="personalized_rejection_row">

                                                                <div className="personalized_rejection_row_label">
                                                                    <span style={{fontSize:"1.1rem", fontWeight:"500"}}>Suggested Reason: </span>
                                                                </div>

                                                                <div className="personalized_rejection_row_value">
                                                                    <span>{predictedReason}</span>
                                                                </div>

                                                            </div>

                                                            <div className="personalized_rejection_row">

                                                                <div className="personalized_rejection_row_label">
                                                                    <span style={{fontSize:"1.1rem", fontWeight:"500"}}>Rejection Message: </span>
                                                                </div>

                                                                <div className="personalized_rejection_row_value">
                                                                    <span className="personalised_rejection_message_content">{rejectionMessage}</span>
                                                                </div>


                                                            </div>


                                                        </div>

                                                        <div className="popup_bottom_actions personalize_actions">
                                                            <div className="personalize_cancel_holder" onClick={closePersonalizePopup}>
                                                                <div className="personalize_cancel">
                                                                    Cancel
                                                                </div>
                                                            </div>

                                                            <div className="personalize_accept_holder" onClick={acceptPersonalizedRejection}>
                                                                <div className="personalize_accept">
                                                                    Accept
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popup>



                                            <div className="candidate_reject_action" onClick={rejectCandidate}>
                                                Reject Candidate
                                            </div>

                                        </>
                                    )}

                                    {(selectedAction.value === "move") && (
                                        <>
                                            <input type="text" placeholder="Subject" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} className="change_stage_email_subject" />

                                            <ReactQuill theme="snow" value={emailBody} onChange={handleEmailBodyChange} placeholder="Please enter the body here..." className="change_stage_email_body" id="change_stage_email_body"/>

                                            <div className="candidate_move_action" onClick={() => moveCandidate()}>
                                                Move Candidate
                                            </div>

                                        </>
                                    )}

                                    {(selectedAction.value === "hire") && (
                                        <div className="hire_candidate_body">
                                            <span className="hire_candidate_message">Congrats on finding your ideal candidate!</span>

                                            <p>On clicking 'Hire Candidate', this job will be marked as 'Filled' and the candidate will be marked as hired. </p>

                                            <p>You may also choose to <span className="hire_send_an_email">send an email</span> directly (optional) or contact the candidate seperately!</p>

                                            <div className="hire_candidate_send_email_holder">

                                               <div className="hire_candidate_email_subject_holder">
                                                    <input type="text" placeholder="Subject" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} className="change_stage_email_subject" style={{marginBottom:"0rem", width: "55%"}} />
                                                    <div className="send_email_template" style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:0}}>
                                                        <select className="send_email_select" onChange={(e) => loadTemplate(e.target.value)} style={{marginTop:0}}>
                                                            <option value="default" selected >Load Template</option>
                                                            <option value="no_update">No Update</option>
                                                            {employerData && employerData.settings.email_templates.custom.map((template, index) => (
                                                                <option value={index} key={index}>{template.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <ReactQuill theme="snow" value={emailBody} onChange={handleEmailBodyChange} placeholder="Please enter the body here..." className="change_stage_email_body" id="change_stage_email_body" />

                                             </div>


                                             <div className="candidate_move_action candidate_hire_action" onClick={() => hireCandidate()}>
                                                 Hire Candidate
                                             </div>

                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                            </Popup>

                            <Popup
                             open={schedulingPopupOpen}
                             onOpen={() => setSchedulingPopupOpen(true)}
                             modal
                             nested
                             position="center center"
                            >
                                <ScheduleInterviewPopup 
                                candidate={candidate}
                                job={job}
                                employerData={employerData}
                                shiftedStage={true} 
                                closePopup={() => setSchedulingPopupOpen(false)} 
                                closeGlobalPopup={closePopup}                  
                                />             
                            </Popup>
                            </>
                        ))}
                    </div>

                </div>
            </div>
        </Popup>

    )
}
