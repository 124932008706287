import { X } from "lucide-react";
import { useEffect, useState } from "react"
import ReactQuill from "react-quill";
import Popup from "reactjs-popup";
import { LocationEntry } from "../../components/location";
import { stagesNames } from "../job";
import * as FaIcons from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import "./editJob.css";
import { sendNotification } from "../../components/notification";
import { EditSkillScore } from "./editJobStages/editSkillScore";
import { EditInterview } from "./editJobStages/editInterview";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../firebase";
import { EditPrescreening } from "./editJobStages/editPrescreening";


var originalSkillScore = null;
var originalJob = null;


export const EditJob = ({job, setJob,jobID, employer, setAllPopupsClosed}) => {

    useEffect(() => {
        if (originalJob === null) {
            originalJob = job;
        }
    }, [job])

    const [popupIsOpen, setPopupOpen] = useState(false);
    
    // Handling the changing of the stages, so that you know how to change them.

    const [instructions, setInstructions] = useState([]);

    const changeStage = (stage) => {
        var currStage = stage;
        

        instructions.forEach((instruction) => {
            if (instruction.type === "add") {
                if (instruction.index <= currStage) {
                    currStage += 1;
                }                
            } else if (instruction.type === "delete") {
                if (instruction.direction === "previous") {
                    if (instruction.index <= currStage) {
                        currStage -= 1;
                    }
                }
            } else if (instruction.type === "switch") {
                if (instruction.index.from <= currStage && instruction.index.to >= currStage) {
                    currStage -= 1
                } else if (instruction.index.from >= currStage && instruction.index.to <= currStage) {
                    currStage += 1;
                }
            }
        })

    }


    const handleClosingPopup = () => {
        setPopupOpen(false);
        setAllPopupsClosed();
    }

    // handling all the new details

    const [title, setTitle] = useState("old name");

    const [jobDepartment, setJobDepartment] = useState("");

    const [jobDesc, setJobDesc] = useState('old desc');


    const handleDescChange = (content, delta, source, editor) => {
        setJobDesc(editor.getHTML()); // or content for just the HTML content
    };


    // obviously all of this information comes from the actual job
    const [location, setLocation] = useState(null);

    const [isRemote, setIsRemote] = useState(false);

    useEffect(() => {
        setLocation(null);
    }, [isRemote])

    useEffect(() => {
        if (location !== null) {
          setIsRemote(false);
        }
    }, [location])


    const [salaryFrom, setSalaryFrom] = useState('');

    const handleSalaryFromChange = (e) => {
        const newValue = e.target.value.replace(/[^0-9.]/g, '');
        setSalaryFrom(newValue);
    };

    const [salaryTo, setSalaryTo] = useState('');

    const handleSalaryToChange = (e) => {
        const newValue = e.target.value.replace(/[^0-9.]/g, '');
        setSalaryTo(newValue);
    };

    const [positionType, setPositionType] = useState("Full-Time");

    const [pipeline, setPipeline] = useState(null);


    useEffect(() => {
        setPipeline(job.pipeline);
        const originalPipeline = job.pipeline;
        originalSkillScore = originalPipeline.find(stage => stage.label === "skillscore");
    }, [job])

     // handling the drag n drop functionality


    const onDragStart = (result) => {}

    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        // Extract the index from the draggableId
        const draggedIndex = parseInt(result.draggableId.split('-')[1], 10);

        if (isNaN(draggedIndex)) {
            console.error('Invalid draggableId format');
            return;
        }

        const stage = pipeline[draggedIndex];
        const type = stage.label;

        if (type === "applied" || type === "hired" || type === "made_offer" || type === "skillscore") {
            sendNotification("You cannot move this stage!");
            return;
        }

        const droppedIndex = result.destination.index

        console.log(droppedIndex);

        // add the switch instruction

        setInstructions(prevInstructions => {
            const newInstructions = [...prevInstructions, {
                type: "switch",
                index: {
                    from: draggedIndex,
                    to: droppedIndex
                }
            }];
            return newInstructions;
        });

        // check to see if the stages can be ordered in ths way before enabling it. 



        const items = Array.from(pipeline);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        const prevStage = items[droppedIndex - 1];
        const nextStage = items[droppedIndex + 1];


        switch (type) {
            case "prescreening":
                if (!(prevStage?.label === "applied" && nextStage?.label === "skillscore")) {
                    sendNotification("Pre-Screening can only be placed after Applied and before SkillScore!");
                    return;
                }
                break;
            case "interview":
                if (!(prevStage?.label === "interview" || prevStage?.label === "skillscore")) {
                    sendNotification("A manual interview should take place after SkillScore screening!");
                    return;
                }
                break;
            case "made_offer":
                if (!(nextStage?.label === "hired")) {
                    sendNotification("An offer can only be made before hiring!");
                    return;
                } else if (prevStage?.label === "made_offer") {
                    sendNotification("You can only make an offer once!");
                    return;
                }
                break;
        }   
    
        if (prevStage?.label === "hired") {
            sendNotification("You cannot move a stage after hired!");
            return;
        }
        
        // Update the pipeline with new indices
        const updatedPipeline = items.map((item, index) => ({
            ...item,
            index: index
        }));

        setPipeline(updatedPipeline);
    };

    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const deletePipelineStage = (index) => {

        const stage = pipeline[index];

        if (stage.label === "applied" || stage.label === "hired" || stage.label === "made_offer" || stage.label === "skillscore") {
            sendNotification("You cannot delete this stage!");
            return;
        }

        setDeletePopupOpen(true);
        setDeleteIndex(index);
        
    }

    const confirmDelete = async (direction) => {
        setDeletePopupOpen(false);
        const updatedPipeline = [...pipeline];
        updatedPipeline.splice(deleteIndex, 1);
        setPipeline(updatedPipeline);

        const updatedInstructions = [...instructions, {
            type: "delete",
            index: deleteIndex,
            direction
        }];

        setInstructions(updatedInstructions);
    }

    // handlign loading all of these things

    useEffect(() => {
        if (job) {
            setTitle(job.jobTitle);
            setJobDepartment(job.department);
            setJobDesc(job.jobDesc);
            setLocation(job.location);
            setPositionType(job.position_type);
            setSalaryTo(job.salary.to);
            setSalaryFrom(job.salary.from);
            setIsRemote(job.remote);
            setPipeline(job.pipeline);
        }
    }, [job])


    // handling adding a stage to the pipeline

    const [addPopupOpen, setAddPopupOpen] = useState(false);

    const addStage = (stage) => {
        setAddPopupOpen(false);
        let addIndex = null;
        if (stage === "prescreening") {

            for (let i = 0; i < pipeline.length; i++) {
                if (pipeline[i].label === "prescreening") {
                    sendNotification("You already have a prescreening stage in the pipeline!");
                    return;
                }
            }   

            for (let i = 0; i < pipeline.length; i++) {
                if (pipeline[i].label === "applied") {
                    const newStage = {
                        label: "prescreening",
                    };

                    const newPipeline = [...pipeline];
                    newPipeline.splice(i + 1, 0, newStage);
                    addIndex = i + 1;
                    setJob({...job, pipeline: newPipeline});
                    setPipeline(newPipeline);
                    break;
                }
            }

        }

        if (stage === "interview") {
            console.log("adding interview stage")

            // add a new interview stage at the second last stage of the pipeline. 



            const newStage = {
                label: "interview",
                complete: false,
            };

            const newPipeline = [...pipeline];
            addIndex = newPipeline.length - 1;
            newPipeline.splice(newPipeline.length - 1, 0, newStage);
            
            setJob({...job, pipeline: newPipeline});
            setPipeline(newPipeline);
        }

        // add the add instruction

        if (addIndex !== null) {
            const updatedInstructions = [...instructions, {
                type: "add",
                index: addIndex,
            }];


            setInstructions(updatedInstructions);
        }
    }


    // handling the saving of the job

    const handleRescoringCandidates = async (updatedPipeline) => {
        // in case the weightings of skillscore have been changed, the scores of the candidates have to be changed accordingly. 
        const oldSkillScoreStage = originalSkillScore;
        const updatedSkillScoreStage = updatedPipeline.find(stage => stage.label === "skillscore");

        if (oldSkillScoreStage && updatedSkillScoreStage) {
            const hasSkillScoreChanged = JSON.stringify(oldSkillScoreStage) !== JSON.stringify(updatedSkillScoreStage);
            
            if (hasSkillScoreChanged) {
                fetch('https://api.skillpool.tech/recalculate_candidates_scores', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        jobID,
                        job
                    })
                })
            } else {
                return;
            }
        } else {
            return;
        }

    }

    const handleCancel = () => {
        setJob(originalJob);
        handleClosingPopup();
    }    

    const changeAllStages = async () => {

        const jobDetails = {
            jobTitle: title,
            department: jobDepartment,
            jobDesc: jobDesc,
            location: location,
            remote: isRemote,
            position_type: positionType,
            salary: {
                from: salaryFrom,
                to: salaryTo
            },
            pipeline: pipeline
        }

        await fetch('https://api.skillpool.tech/edit_job_stages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                instructions, 
                jobID,
                job: jobDetails
            }),
        });
    }

    const handleSavingJob = async () => {

        // first check to see if anything is incomplete

        for (let i = 0; i < pipeline.length; i++) {
            if (pipeline[i].complete !== undefined) {
                if (pipeline[i].deleted !== true) {
                  if (!pipeline[i].complete) {
                    sendNotification("Please complete all parts of the pipeline!");
                    return;
                  }
                }
            }
        
        }

        // edit the job details 

        const jobDetails = {
            jobTitle: title,
            department: jobDepartment,
            jobDesc: jobDesc,
            location: location,
            remote: isRemote,
            position_type: positionType,
            salary: {
                from: salaryFrom,
                to: salaryTo
            },
            pipeline: pipeline
        }

        // IF THE RATINGS FOR SKILLSCORE HAVE BEEN CHANGED, UPDATE THE SCORE CALCULATIONS FOR ALL OF THE CANDIDATES. 
        handleRescoringCandidates(pipeline);

        // // edit the job on the firebase database
        setJob(jobDetails);

        const jobRef = doc(db, "jobs", jobID);
        await updateDoc(jobRef, jobDetails);


        await changeAllStages();

        handleClosingPopup();
        window.location.reload();

        // should probably also reload the page here.
    }

    return (
        <>
        <Popup trigger={
            <div className="job_action">
              Edit Job
            </div>
          }
          open={popupIsOpen}
          onOpen={() => setPopupOpen(true)}
          modal
          nested
          position="center center"
          >
            <div className="popup_holder">
              <div className="popup">
                <h1 className="popup_heading" style={{margin: 0, marginBottom:"1rem"}}>Edit Job</h1>
                <div className="popup_close" onClick={handleCancel}>
                    <X />
                </div>
                
                <div className="popup_body edit_job_body">

                    <div className="edit_job_details edit_job_section">
                        <div className="edit_job_detail" style={{flexDirection:"row", alignItems:"center", marginBottom: "2rem"}}>
                            <p style={{margin:0, marginRight:"0.5rem"}}>Title: </p>

                            <input
                                type="text"
                                className="new_question_input edit_job_input"
                                style={{width: "100%"}}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Enter the job title!"
                            />
                        </div>

                        <div className="edit_job_detail" style={{flexDirection:"row", alignItems:"center", marginBottom: "2rem"}}>
                            <p style={{margin:0, marginRight:"0.5rem"}}>Department: </p>

                            <select name="job_type" id="job_type" className="create_job_select" style={{width: "100%"}} onChange={(e) => setJobDepartment(e.target.value)} value={jobDepartment}>
                                <option value="" selected disabled>Select</option>
                                <option value="Software Development">Software Development</option>
                                <option value="Data Science">Data Science</option>
                                <option value="Product Management">Product Management</option>
                                <option value="Marketing">Marketing</option>
                                <option value="Finance">Finance</option>
                                <option value="Sales">Sales</option>
                                <option value="Engineering">Engineering</option>
                                <option value="Design">Design</option>
                                <option value="Business">Business</option>
                                <option value="Consulting">Consulting</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>


                        <div className="edit_job_detail" style={{flexDirection:"column", alignItems:"flex-start"}}>
                            <p>Description: </p>

                            <ReactQuill theme="snow" value={jobDesc} onChange={handleDescChange} placeholder="Please enter the description here..." className="create_job_desc edit_job_desc"/>
                        </div>
                    </div>

                    <div className="edit_job_pipeline_area edit_job_section">
                        <div style={{display:"flex"}}>
                            <div className="edit_job_detail" style={{flexDirection:"column", alignItems:"flex-start", width:"50%"}}>
                                <p>Location: </p>
                                
                                <LocationEntry value={location} setValue={setLocation} additionalClassname="edit_job_location" additionalHolderClassname="edit_job_location_holder"/>
                                <div style={{display: "flex", alignItems:"center"}}>
                                    <div
                                        style={{
                                            border: "1px solid var(--primary)",
                                            width: ".7rem",
                                            height: ".7rem",
                                            borderRadius: "20%",
                                            marginRight:"0.5rem",
                                            cursor: "pointer",
                                            backgroundColor: isRemote ? "var(--primary)" : "transparent"
                                        }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setIsRemote(!isRemote);
                                        }}
                                    />
                                    <p>Is this a fully remote job?</p>
                                </div>

                            </div>

                            <div className="edit_job_detail" style={{flexDirection:"column", alignItems:"flex-start", width:"50%"}}>
                                <p>Job Type: </p>
                                
                                <select name="position_type" value={positionType} onChange={(e) => setPositionType(e.target.value)} id="position_type_select" className="create_job_select">
                                    <option value="" selected disabled>Select</option>
                                    <option value="Full-Time">Full-Time</option>
                                    <option value="Part-Time">Part-Time</option>
                                    <option value="Internship">Internship</option>
                                </select>

                            </div>

                            
                        </div>

                        <div className="salary_range_section edit_job_detail">
                            <p >Salary Range</p>
                            <div className="salary_range">
                                <input type="text" id="salaryFrom" name="salaryFrom" placeholder="From" value={salaryFrom ? `$ ${salaryFrom}` : ''} onChange={handleSalaryFromChange} className="create_job_input salary_input" />
                                <div className="dash">–</div>
                                <input type="text" id="salaryTo" name="salaryTo" placeholder="To" value={salaryTo ? `$ ${salaryTo}` : ''} onChange={handleSalaryToChange} className="create_job_input salary_input" />
                            </div>

                            {/* <div style={{display: "flex", alignItems:"center"}}>
                                <input type="checkbox" id="autorejectIfHigher"/>
                                <p>Automatically reject applicants who demand a higher salary?</p>
                            </div> */}
                        </div>
                        <div className="edit_job_pipeline_holder">

                            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                <p>Pipeline: </p>

                                <Popup trigger={
                                    <div className="edit_job_add_pipeline_stage">
                                        <FaIcons.FaPlus style={{marginRight:"0.5rem"}} />
                                        Add
                                    </div>
                                }
                                contentStyle={{ padding: '0px', border: 'none' }}
                                open={addPopupOpen}
                                onOpen={() => setAddPopupOpen(true)}
                                nested
                                position="left top"
                                >
                                    <div className="pipeline_stage_options">
                                        
                                        <div className="pipeline_stage_option" onClick={() => addStage("prescreening")}>
                                            <p>Prescreening</p>
                                        </div>

                                        <div className="pipeline_stage_option" onClick={() => addStage("interview")}>
                                            <p>Interview</p>
                                        </div>
                                    </div>
                                </Popup>
                                
                            </div>

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="pipeline">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} className="edit_job_pipeline">
                                            {pipeline.map((stage, index) => (
                                                <>
                                                {
                                                    stage.label === "skillscore" && (
                                                        <EditSkillScore
                                                        index={index}
                                                        provided={provided}
                                                        stage={stage}
                                                        setPipeline={setPipeline}
                                                        job={job}
                                                    />
                                                ) }

                                                {
                                                    stage.label === "interview" && (
                                                        <EditInterview
                                                        index={index}
                                                        provided={provided}
                                                        stage={stage}
                                                        deletePipelineStage={deletePipelineStage}
                                                        pipeline={pipeline}
                                                        setPipeline={setPipeline}
                                                        job={job}
                                                        setJob={setJob}
                                                        employer={employer}
                                                    />
                                                    )
                                                }

                                                {
                                                    stage.label === "prescreening" && (
                                                        <EditPrescreening
                                                        index={index}
                                                        provided={provided}
                                                        stage={stage}
                                                        deletePipelineStage={deletePipelineStage}
                                                        job={job}
                                                        employer={employer}
                                                    />
                                                    )
                                                }

                                                { (stage.label !== "skillscore") && (stage.label !== "interview") && (stage.label !== "prescreening") && (
                                                    <Draggable key={stage.id || index} draggableId={`stage-${index}`} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="edit_pipeline_stage"
                                                            >
                                                                <div className="stage_details" style={{display:"flex", alignItems:"center"}}>
                                                                    <div style={{width: "2rem"}}>
                                                                        {
                                                                            stage.label === "interview" && (
                                                                                <FaIcons.FaGripVertical style={{ cursor: 'grab' }} />
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <p style={{margin:0, marginRight:"0.5rem", width:"10rem"}}>{stagesNames[stage.label]}</p>

                                                                    {
                                                                        (stage.label === "applied" || stage.label === "hired" || stage.label === "made_offer") ? (
                                                                            <>
                                                                            </>
                                                                    ) : (
                                                                        <p style={{margin:0, opacity:0.5, fontWeight:"500"}}>Click to Edit</p>
                                                                    )}
                                                                </div>
                                                                
                                                                <div className="stage_actions">
                                                                    {
                                                                        ((stage.label === "applied" || stage.label === "hired" || stage.label === "made_offer" || stage.label === "skillscore")) ? (
                                                                            <>
                                                                            </>
                                                                        ) : (
                                                                            <FaIcons.FaTrash className="stage_action_icon" onClick={(e) => deletePipelineStage(index)} />
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                                }
                                                </>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>

                        
                    
                </div>

                <div className="popup_bottom_actions">
                    <div className="popup_cancel" onClick={handleClosingPopup}>
                        Cancel
                    </div>

                    <div className="popup_action" onClick={handleSavingJob}>
                        Save
                    </div>
                </div>

              </div>
            </div>
        </Popup>

        <Popup
            open={deletePopupOpen}
            onOpen={() => setDeletePopupOpen(true)}
            modal
            nested
            position="center center"
        >
            <div className="popup_holder">
                <div className="popup" style={{width: "40%", height:"40%"}}>
                    <h1 className="popup_heading" style={{margin: 0, marginBottom:"1rem"}}>Are you sure?</h1>

                    <div className="popup_close" onClick={() => setDeletePopupOpen(false)}>
                        <X />
                    </div>

                    <div className="popup_body">
                        <p>Are you sure you want to delete this stage?</p>

                        <hr style={{margin: "2rem 0"}}></hr>

                        <h3>Candidate Handling</h3>

                        <p>Do you want to assign the candidates to the previous stage or next?</p>
                    </div>

                    <div className="delete_stage_bottom_actions">
                        <div 
                            className={`delete_stage_previous delete_stage_direction ${deleteIndex === 1 ? 'disabled' : ''}`}
                            onClick={() => deleteIndex !== 1 && confirmDelete("previous")}
                            style={{ opacity: deleteIndex === 1 ? 0.5 : 1, cursor: deleteIndex === 1 ? 'not-allowed' : 'pointer' }}
                        >
                            Previous
                        </div>

                        <div 
                            className={`delete_stage_next delete_stage_direction ${deleteIndex === pipeline?.length - 2 ? 'disabled' : ''}`} 
                            onClick={() => deleteIndex !== pipeline?.length - 2 && confirmDelete("next")}
                            style={{ opacity: deleteIndex === pipeline?.length - 2 ? 0.5 : 1, cursor: deleteIndex === pipeline?.length - 2 ? 'not-allowed' : 'pointer' }}
                        >
                            Next
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
        </>
    )
}


