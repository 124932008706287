import React, { useState, useEffect, useMemo } from 'react';
import * as FaIcons from 'react-icons/fa';
import { sendNotification } from '../../components/notification';

export const JobSourcing = () => {
    const [searchResults, setSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [failedSearch, setFailedSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(5);
    const [numResults, setNumResults] = useState(0);
    const [params, setParams] = useState({});
    const [loadingEnd, setLoadingEnd] = useState(0);

    const [allDataLoaded, setAllDataLoaded] = useState(false);

    const totalPages = 100; 

    const getMoreData = async ({start}) => {
        console.log("GETTING MORE DATA IS CALLED", start);

        if (allDataLoaded) {
            return;
        }

        setIsLoading(true);
        setLoadingEnd(start + 100);
        console.log(searchResults.map);
        const response = await fetch('https://api.skillpool.tech/get_query_results', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                params: params,
                start: start, 
                currentResults: searchResults.map(result => result.link),
            })
        })
        const data = await response.json();
        console.log(data);
        setSearchResults(searchResults.concat(data.results));

        if (data.results.length < 70) {
            setAllDataLoaded(true);
        }

        setIsLoading(false);
    }

    const fetchData = async (page) => {
        console.log("FETCH DATA IS CALLED");

        setIsLoading(true);
        try {
            const response = await fetch('https://api.skillpool.tech/get_query_results', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: searchQuery,
                    num: resultsPerPage,
                    start: (page - 1) * resultsPerPage + 1,
                }),
            });
            const data = await response.json();

            console.log(data);
            setSearchResults(data.results);
            setNumResults(data.numResults);
            setParams(data.params);
        } catch (error) {
            console.error("Error fetching data:", error);
            setFailedSearch(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = () => {
        setFailedSearch(false);
        setSearchResults([]);
        setResultsPerPage(10);
        setNumResults(0);
        setAllDataLoaded(false);
        setLoadingEnd(0);

        if (searchQuery === '') {
            sendNotification("Please enter a query to search for candidates!");
            return;
        }

        setCurrentPage(1);
        fetchData(1);
    };

    const handlePageChange = (newPage) => {
        const maxVisiblePages = 5;
        const halfVisible = Math.floor(maxVisiblePages / 2);

        console.log((newPage - 1) * resultsPerPage, searchResults.length, loadingEnd);

        if ((newPage - 1) * resultsPerPage >= Math.max(searchResults.length, loadingEnd) / 2 ) {
            console.log("we should probably get more data here", searchResults.length, loadingEnd, (newPage - 1) * resultsPerPage);
            getMoreData({start: searchResults.length + 1});
        }

        setCurrentPage(newPage);

        if (newPage === startPage + 1 || newPage === endPage - 1) {
            // If the new page is the second or second-last in the current series, don't change start/end
            return;
        }

        if (newPage <= halfVisible + 1) {
            setStartPage(1);
            setEndPage(Math.min(totalPages, maxVisiblePages));
        } else if (newPage >= totalPages - halfVisible) {
            // New page is near the end, adjust to make it the center
            setStartPage(Math.max(1, totalPages - maxVisiblePages + 1));
            setEndPage(totalPages);
        } else {
            // New page should be the center
            setStartPage(newPage - halfVisible);
            setEndPage(newPage + halfVisible);
        }
    };

    const handleResultsPerPageChange = (e) => {
        const newResultsPerPage = parseInt(e.target.value);
        const oldResultsPerPage = resultsPerPage;
        setResultsPerPage(newResultsPerPage);
        
        // Calculate the new current page to keep the top result the same
        const topResultIndex = (currentPage - 1) * oldResultsPerPage;
        var newCurrentPage = Math.floor(topResultIndex / newResultsPerPage) + 1;
        
        setCurrentPage(Math.min(newCurrentPage, 100));

        const candidateSearchResults = document.querySelector('.candidate_search_results');
        if (candidateSearchResults) {
            candidateSearchResults.style.overflowY = newResultsPerPage === 10 ? 'hidden' : 'auto';
        }

        // Adjust start and end page
        const maxVisiblePages = 5;
        const halfVisible = Math.floor(maxVisiblePages / 2);

        console.log(newCurrentPage, halfVisible, totalPages);

        if (newCurrentPage <= halfVisible + 1) {
            console.log("WE ARE HERE", totalPages, maxVisiblePages);
            setStartPage(1);
            setEndPage(Math.min(totalPages, maxVisiblePages));
        } else if (newCurrentPage >= totalPages - halfVisible) {
            console.log("WE ARE HERE 2", totalPages, maxVisiblePages);
            setStartPage(Math.max(1, totalPages - maxVisiblePages + 1));
            setEndPage(totalPages);
        } else {
            console.log("WE ARE HERE 3", newCurrentPage, halfVisible);
            setStartPage(newCurrentPage - halfVisible);
            setEndPage(newCurrentPage + halfVisible);
        }
    };

    const pageNumbers = useMemo(() => {
        console.log("PAGE NUMBERS IS CALLED", startPage, endPage);
        const numbers = [];
        for (let i = startPage; i <= endPage; i++) {
            numbers.push(i);
        }
        return numbers;
    }, [startPage, endPage]);
    
    function formatNumResults(num) {
        if (num >= 50) {
            if (num >= 5000000) {
                return Math.round(num / 5000000) * 5 + 'm+';
            } else if (num >= 500000) {
                return Math.round(num / 500000) * 500 + 'k+';
            } else if (num >= 50000) {
                return Math.round(num / 50000) * 50 + 'k+';
            } else if (num >= 5000) {
                return Math.round(num / 5000) * 5 + 'k+';
            } else if (num >= 500) {
                return Math.round(num / 500) * 500 + '+';
            } else if (num >= 50) {
                return Math.round(num / 50) * 50 + '+';
            }
        } else {
            return num.toString();
        }
    }

    return (
        <div className="sourcing_holder">
            <div className='sourcing_search_holder'>
                <input 
                    className='sourcing_search_input' 
                    type="text" 
                    placeholder="Eg. Software Developer living in San Francisco currently working at a startup" 
                    value={searchQuery} 
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                />
                <button onClick={handleSearch} className="sourcing_search_button" disabled={isLoading}>
                    <FaIcons.FaSearch style={{marginRight: '0.5rem'}} />
                    Search
                </button>
            </div>

            <div className='candidate_search_results'>
                {((searchResults.length > ((currentPage - 1) * resultsPerPage)) ) ? (
                    searchResults.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage).map((result, index) => (
                        <div key={index} className='candidate_search_result' >
                            <a href={result.link} className='candidate_search_result_link' style={{textDecoration: 'none', color: 'inherit'}} target="_blank" rel="noopener noreferrer">
                                    
                                    <div className='sourcing_result_info'>
                                        {(() => {
                                            const segments = result.title.split(' - ').map(s => s.trim());
                                            if (segments.length >= 3) {
                                                const words = segments[0].split(' ');
                                                words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
                                                words[words.length - 1] = words[words.length - 1].charAt(0).toUpperCase() + words[words.length - 1].slice(1);
                                                const capitalizedName = words.join(' ');
                                                return (
                                                    <>
                                                        <p className='sourcing_result_info_name'>{capitalizedName}</p>

                                                        <div className='sourcing_result_info_job_company'>
                                                            <div className='sourcing_result_info_job'>
                                                                <FaIcons.FaBriefcase className='sourcing_result_icon' />
                                                                <p>{segments[1]}</p>
                                                            </div>
                                                            <div className='sourcing_result_info_company'>
                                                                <FaIcons.FaBuilding className='sourcing_result_icon' />
                                                                <p>{segments[2]}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            } else if (segments.length === 2) {
                                                const locationSegments = segments[1].split(',').map(s => s.trim());
                                                const words = segments[0].split(' ');
                                                words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
                                                words[words.length - 1] = words[words.length - 1].charAt(0).toUpperCase() + words[words.length - 1].slice(1);
                                                const capitalizedName = words.join(' ');
                                                if (locationSegments.length > 2) {
                                                    return (
                                                        <>
                                                            <p className='sourcing_result_info_name'>{capitalizedName}</p>

                                                            <div className='sourcing_result_info_job_company'>
                                                                <div className='sourcing_result_info_location'>
                                                                    <FaIcons.FaMapMarkerAlt className='sourcing_result_icon' />
                                                                    <p>{segments[1].split(',')[0]}</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <>
                                                            <p className='sourcing_result_info_name'>{capitalizedName}</p>

                                                            <div className='sourcing_result_info_job_company'>
                                                                <div className='sourcing_result_info_company'>
                                                                    <FaIcons.FaBuilding className='sourcing_result_icon' />
                                                                    <p>{segments[1]}</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                }
                                            } else {
                                                const words = result.title.split(' ');
                                                words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
                                                words[words.length - 1] = words[words.length - 1].charAt(0).toUpperCase() + words[words.length - 1].slice(1);
                                                const capitalizedTitle = words.join(' ');
                                                return <p className='sourcing_result_info_name'>{capitalizedTitle}</p>;
                                                
                                            }
                                        })()}
                                    </div>
                                    
                                    <div className='sourcing_result_info_description'>
                                        <p>{result.description}</p>
                                    </div>

                                    <div className='sourcing_result_info_link'>
                                        <FaIcons.FaExternalLinkAlt />
                                    </div>
                            </a>
                        </div>
                    ))
                ) : failedSearch ? (
                    <div className="sourcing_no_results">No results found!</div>
                ) : (
                    isLoading ? (
                        <div className="sourcing_no_results loading_holder">
                            <div className="loader"></div>
                        </div>
                    ) : (
                        allDataLoaded ? (
                            <div className="sourcing_no_results">No more results!</div>
                        ) : (
                            <div className="sourcing_no_results">Make a search!</div>
                        )
                    )
                )}
            </div>

            <div className="sourcing_footer">

                <div className="results_per_page">
                    <select value={resultsPerPage} onChange={handleResultsPerPageChange}>
                        <option value="10">10 per page</option>
                        <option value="20">20 per page</option>
                        <option value="50">50 per page</option>
                    </select>
                </div>

                {searchResults.length > 0 ? (
                    <div className="sourcing_pagination">
                        
                        <button 
                            className="pagination_nav_button pagination_button--prev"
                            onClick={() => handlePageChange(currentPage - 1)} 
                            disabled={currentPage === 1 || isLoading}
                        >
                            <FaIcons.FaChevronLeft />
                            <span>Previous</span>
                        </button>

                        <div className="pagination_numbers">
                            {pageNumbers.map(number => (
                                <button
                                    key={number}
                                    className={`pagination_button pagination_button--number ${currentPage === number ? 'pagination_button--active' : ''}`}
                                    onClick={() => handlePageChange(number)}
                                    disabled={(!(currentPage !== number || !isLoading || ((number - 1) * resultsPerPage < searchResults.length)) || (allDataLoaded && (number - 1) * resultsPerPage > numResults))}
                                >
                                    {number}
                                </button>
                            ))}
                        </div>

                        <button 
                            className="pagination_nav_button pagination_button--next"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages || isLoading || (allDataLoaded && (currentPage) * resultsPerPage > numResults)}
                        >
                            <span>Next</span>
                            <FaIcons.FaChevronRight />
                        </button>
                    </div>
                ) : <div className='sourcing_pagination'></div>
                }

                <div className='num_results_info'>
                    {
                        numResults > 0 ? (
                            <p>Showing {(currentPage - 1) * resultsPerPage + 1} – {Math.min((currentPage - 1) * resultsPerPage + resultsPerPage, numResults)} of {formatNumResults(numResults)} results</p>
                        ) : (
                            <></>
                        )
                    }
                </div>



            </div>

            
        </div>
    );
};
